import React, { useState, useEffect, useRef } from 'react'
import Modal from 'react-bootstrap/Modal';
import classes from "./EditCompanyModal.module.css"
import useOnClickOutside from '../useCustomHooks/useOnClickOutside';
import { ImCross } from 'react-icons/im';
import Loader from 'react-loader-spinner';
import { GrImage, GrList, GrZoomIn, GrZoomOut } from "react-icons/gr";
import { AiOutlineCloseCircle, AiOutlineClose } from "react-icons/ai";
import { curr } from "../Json files/Currency";
import { states } from "../Json files/States";

const ShowCompanyAndJobModal = (props) => {
    const handleClose = () => props.setShow(false);
    const [data, setData] = useState({});
    const [mobileCompany, setMobileCompany] = useState(null);
    const [loading, setLoading] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [compDesc, setCompDesc] = useState()
    const [selectedCompanyLoc, setSelectedCompanyLoc] = useState()
    const [addressCompany, setAddressCompany] = useState()
    const [emailCompany, setEmailCompany] = useState()
    const [industry, setIndustry] = useState()
    const [governmentCompany, setGovernmentCompany] = useState()

    const [existingLoc, setExistingLoc] = useState()
    const [websiteCompany, setWebsiteCompany] = useState()
    const [companySuggestions, setCompanySuggestions] = useState([]);
    const [companySuggestionsShow, setCompanySuggestionsShow] = useState(true);
    const [selectedCompId, setSelectedCompId] = useState();
    const [companyLogo, setCompanyLogo] = useState();
    const [saveLoading, setSaveLoading] = useState(false);
    const [openJob, setOpenJob] = useState(false)
    const [jobApplicationDetail, setJobApplicationDetail] = useState("");
    const [positionTitle, setPositionTitle] = useState("");
    const [jobFunction, setJobFunction] = useState("");
    const [level, setLevel] = useState("");
    const [jobDescription, setJobDescription] = useState("");
    const [minSalary, setMinSalary] = useState("");
    const [maxSalary, setMaxSalary] = useState("");
    const [email, setEmail] = useState("");
    const [employementType, setEmployementType] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [currency, setCurrency] = useState("INR");
    const [streetAddress, setStreetAddress] = useState("");
    const [region, setRegion] = useState("");
    const [externalApplyUrl, setExternalApplyUrl] = useState("");
    const [locations, setLocations] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [address, setAddress] = useState("");
    const [walkInStartTime, setWalkInStartTime] = useState("");
    const [walkInEndTime, setWalkInEndTime] = useState("");
    const [selectedLocationsId, setSelectedLocationsId] = useState([]);
    const [selectedLocationsName, setSelectedLocationsName] = useState([]);
    const [adDetailImage, setAdDetailImage] = useState("")
    const [showIndustry, setShowIndustry] = useState(false)
    const [addIndustryValue, setAddIndustryValue] = useState("")
    const [showLocation, setShowLocation] = useState(false)
    const [addLocationValue, setAddLocationValue] = useState("")
    const [seoUrl, setSeoUrl] = useState("");
    
    const [datePosted, setDatePosted] = useState("");
    const [seoUrlValidationMessage, setSeoUrlValidationMessage] = useState("");
    const [isSeoUrlEditable, setIsSeoUrlEditable] = useState(true);
    const [isSeoUrlValid, setIsSeoUrlValid] = useState(false);
    const employmentList = [
        {
            type: "Full-Time",
        },
        {
            type: "Part-Time",
        },
        {
            type: "Contract",
        },
        {
            type: "Temporary",
        },
        {
            type: "Seasonsl",
        },
        {
            type: "Internship",
        },
    ];
    const userEmail = sessionStorage.getItem("googleBiEmail")


    useEffect(() => {
        // Auto-generate and validate SEO URL when dependent fields change
        generateAndValidateSeoUrl();
    }, [positionTitle, companyName, selectedLocationsName, datePosted]);

    const generateSeoUrl = () => {
        // Format date as "MMM-yyyy"
        const formattedDate = new Date(datePosted || new Date())
            .toLocaleDateString("en-US", { month: "short", year: "2-digit" })
            .toLowerCase()
            .replace(/\s+/g, "-");

        // Helper function to clean strings and provide default fallback
        const cleanString = (str, fallback = "unknown") =>
            str
                ?.replace(/[^\w\s-]/g, " ") // Remove non-alphanumeric except dashes
                .replace(/\s+/g, "-")       // Replace spaces with dashes
                .replace(/^-+|-+$/g, "")    // Trim leading/trailing dashes
                .toLowerCase() || fallback; // Fallback if string is empty or null

        // Clean components and provide default values if necessary
        const cleanedPositionTitle = cleanString(positionTitle, "position");
        const cleanedCompanyName = cleanString(companyName, "company");
        const cleanedLocations = cleanString(selectedLocationsName.join("-"), "location");

        // Generate SEO URL and remove any accidental double slashes
        const seoURL = `/new-jobs/${formattedDate}/${cleanedPositionTitle}/${cleanedCompanyName}/${cleanedLocations}`
            .replace(/\/\/+/g, "/"); // Replace any double slashes with single slash

        return seoURL;
    };

    const generateAndValidateSeoUrl = () => {
        if (!positionTitle || !companyName || selectedLocationsName.length === 0) {
            setSeoUrlValidationMessage(
                "Position Title, Company Name, and Location are required to generate a valid SEO URL."
            );
            setIsSeoUrlValid(false);
            return;
        }

        const generatedUrl = generateSeoUrl();
        setSeoUrl(generatedUrl);

        // Validate the generated SEO URL
        handleSeoUrlValidation(generatedUrl);
    };
    // save company api
    const saveCompany = async (e) => {

        setSaveLoading(true)
        let isCompanyExists = false
        await props.companyList.filter((val) => {
            if (val.compName.toLowerCase().toString() === (companyName.toLowerCase())) {
                setSelectedCompanyLoc(val.keyindex)
                isCompanyExists = true;
                return;
            }
        })
        if (!(uploadedImage)) {
            alert("please provide mandotary details")
            return;
        }
        e.preventDefault();
        // handleClose()


        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");

        var formdata = new FormData();
        if (isCompanyExists)
            formdata.append("companyId", selectedCompId);

        formdata.append("name", companyName);
        formdata.append("companyDescription", compDesc || "");
        formdata.append("phone", mobileCompany || "");
        formdata.append("website", websiteCompany || "");
        formdata.append("email", emailCompany || "");
        formdata.append("industry", industry || "");
        formdata.append("governmentCompany", governmentCompany ? "1" : "0");
        formdata.append("address", addressCompany || "");
        formdata.append("locationV2", selectedCompanyLoc || "");
        formdata.append("image", companyLogo || "");
        if (props.orderNumber)
            formdata.append("orderNumber", props.orderNumber);
        formdata.append("userEmail", userEmail);
        //formdata.append("CompanySeourl", seoUrl)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${global.apiLink}/adminapi/${isCompanyExists ? "UpdateCompanyDetails" : "createCompanyForOrderNumber"}`, requestOptions)
            .then(response => response.json())
            .then((result) => {


                if (result.status_code === 200) {
                    createJob(selectedCompId || result.data)
                } else {
                    alert("Company not updated")
                }
            })
            .catch(error => console.log('error', error))
            .finally(() => setSaveLoading(false));
    }
    const createJob = async (compId) => {


        setSaveLoading(true)

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");

        var formdata = new FormData();

        formdata.append("companyId", selectedCompId);
       
        formdata.append("name", companyName);
       
        formdata.append("companyDescription", compDesc || "");
        formdata.append("phone", mobileCompany || "");
        formdata.append("website", websiteCompany || "");
        formdata.append("email", emailCompany || "");
        formdata.append("industry", industry || "");
        formdata.append("governmentCompany", governmentCompany ? "1" : "0");
        formdata.append("address", addressCompany || "");
        formdata.append("locationV2", selectedCompanyLoc || "");
        formdata.append("image", companyLogo || "");
        formdata.append("applicationDetails", jobApplicationDetail || "");
        formdata.append("positionTitle", positionTitle || "");
        formdata.append("jobCategory", jobFunction || "");
        formdata.append("level", level || "");
        formdata.append("jobDescription", jobDescription || "");
        formdata.append("minSalary", minSalary || "");
        formdata.append("maxCtc", maxSalary || "");
        formdata.append("email", email || "");
        formdata.append("EmploymentType", employementType || "");
        formdata.append("PostalCode", postalCode || "");
        formdata.append("Currency", currency || "");
        formdata.append("industry", industry || "");
        formdata.append("StreetAddress", streetAddress || "");
        formdata.append("AddressRegion", region || "");
        formdata.append("externalApplyUrl", externalApplyUrl || "");
        formdata.append("location", selectedLocationsId.join(",") || "");
        formdata.append("WalkinStartDate", startDate || "");
        formdata.append("WalkinEndDate", endDate || "");
        formdata.append("WalkinAddress", address || "");
        formdata.append("WalkinTime", `${walkInStartTime}-${walkInEndTime}` || "");
        formdata.append("userEmail", userEmail || "");
        formdata.append("companyId", compId || "");
        formdata.append("image", uploadedImage);
        formdata.append("SeoURL", seoUrl);
       
   
        


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/adminapi/createNewJobNonAscentJobs", requestOptions)
            .then(response => response.json())
            .then((result) => {


                if (result.status_code === 200) {
                    handleClose();
                    alert("Job Created")
                } else {
                    alert("Company not updated")
                }
            })
            .catch(error => console.log('error', error))
            .finally(() => setSaveLoading(false));
    }



    const handleSeoUrlChange = (value) => {
        setSeoUrl(value);
        handleSeoUrlValidation(value);
    };


    const handleSeoUrlValidation = async (value) => {
        // Ensure required fields are present
        if (!positionTitle || !companyName || selectedLocationsName.length === 0) {
            setSeoUrlValidationMessage(
                "Position Title, Company Name, and Location are required to generate a valid SEO URL."
            );
            setIsSeoUrlValid(false);
            return;
        }

        // API call to check if SEO URL exists
        const requestBody = { SeoURL: value };
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: "Token 77927b69bb144b065ca11bf2a9d452819cd852db",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
        };

        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL + "/adminapi/CheckSeoURLinLatestJobs",
                requestOptions
            );
            const result = await response.json();

            if (result.status_code === 200 && result.status === "SUCCESS") {
                if (result.IsSeoURLExist) {
                    setSeoUrlValidationMessage("SEO URL already exists. Please modify it.");
                    setIsSeoUrlValid(false);
                } else {
                    setSeoUrlValidationMessage("SEO URL is valid.");
                    setIsSeoUrlValid(true);
                }
            } else {
                setSeoUrlValidationMessage("Invalid SEO URL format.");
                setIsSeoUrlValid(false);
            }
        } catch (error) {
            setSeoUrlValidationMessage("Error validating SEO URL.");
            setIsSeoUrlValid(false);
        }
    };


    useEffect(() => {
        if (companyName !== "") {
            let val = false;
            setCompanySuggestions(props.companyList.filter((val) => {
                if (val.compName === companyName) {
                    val = true
                }
                return (val.compName ? val.compName.toLowerCase().toString().startsWith(companyName.toLowerCase()) : null)
            }))
            if (val) {
                setCompanySuggestionsShow(false)

            }
            setCompanySuggestionsShow(true)
        }
        else {
            setCompanySuggestions([])
            setCompanySuggestionsShow(false)
        }
    }, [companyName])


    const getCompanyDetail = (companyId, companyName) => {

        setCompanySuggestionsShow(false);
        setSelectedCompId(companyId)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "CompanyId": companyId
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(global.apiLink + "/v1/api/apiTimes/GetCompanyDetail", requestOptions)
            .then(response => response.json())
            .then(result => {

                setData(result.data)
                setCompanyName(result.data.CompanyName || "");
                setCompDesc(result.data.companyDescription || "")
                setAddressCompany(result.data.address || "")
                setIndustry(result.data.IndustryId || "")
                setWebsiteCompany(result.data.website || "")
                setMobileCompany(result.data.phone || "")
                setEmailCompany(result.data.email || "")
                setExistingLoc(result.data.city || "")
                setSelectedCompanyLoc(result.data.locid || "")
            })
            .catch(error => console.log('error', error));
    }
    const companyNameRef = useRef(null)
    useOnClickOutside(companyNameRef, () => setCompanySuggestionsShow(false))
    const [uploadedImage, setUploadedImage] = useState()
    const [showJobModal, setShowJobModal] = useState(false);
    const findLocationIdHandler = (locationName) => {
        // console.log(locationName + "fff" + selectedLocationsId)

        if (selectedLocationsName.includes(locationName)) {
            return;
        }
        props.locationList.find((val) => {
            if (locationName === val.locName) {
                setSelectedLocationsId((preLocationId) => {
                    return [...preLocationId, val.id];
                });
            }
        });
    };
    //add function for list of locations

    const addLocationHandler = (selectedLocation) => {

        if (selectedLocationsName.includes(selectedLocation)) {
            return;
        }
        setSelectedLocationsName((preLocation) => {
            return [...preLocation, selectedLocation];
        });
    };

    //delete function for delete location box

    const deleteLocationName = (id) => {
        setSelectedLocationsName((preLocation) => {
            return preLocation.filter((item, index) => {
                return index !== id;
            });
        });
    };
    const deleteLocationId = (id) => {

        setSelectedLocationsId((preLocationId) => {
            return preLocationId.filter((item, index) => {
                return index !== id;
            });
        });
    };
    return <>
        <Modal
            show={props.show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            // size="lg"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Body>
                {props.loading ? <div className={classes.center}>
                    <Loader type="TailSpin" color="#00BFFF" height={80} width={80} />

                </div> : <div className={classes.d_flex}>
                    <div className={classes.imgDiv}>
                        {<img src={props.createJob && uploadedImage ? URL.createObjectURL(uploadedImage) : props.adDetailImage} width="100%" height={props.createJob ? "90%" : "100%"} />}
                        {props.createJob ? <input type="file" accept="image/*" onChange={(e) => { setUploadedImage(e.target.files[0]) }} /> : <></>}
                    </div>
                    <form className={classes.formDiv} onSubmit={(e) => { saveCompany(e) }}>
                        <div className={classes.header}>
                            <h4>Company Details</h4>
                            <ImCross onClick={handleClose} />
                        </div>
                        <div>

                            <div>
                                <label>Company Name<span>*</span></label>
                                <input type="text" required value={companyName} onChange={(e) => { setCompanyName(e.target.value) }} />

                                {companySuggestionsShow && companySuggestions.length > 0 ? <div className={classes.suggestion} ref={companyNameRef}> <div>
                                    {
                                        companySuggestions.map((val) => {
                                            return <p onClick={() => {
                                                getCompanyDetail(val.keyindex, val.compName)
                                            }}>
                                                {val.compName}
                                            </p>
                                        })
                                    }
                                </div>
                                </div>
                                    :
                                    <></>
                                }
                            </div>
                            <div>
                                <label>Industry<span>*</span></label>
                                <select value={industry} required onChange={(e) => { setIndustry(e.target.value) }}>
                                    <option value="">Choose Industry</option>
                                    {
                                        props.industryList.map((item) => {
                                            return (
                                                <option value={item.id}>{item.industryName}</option>
                                            )
                                        })
                                    }
                                </select>
                                {/* {showIndustry ? <></> : <div className={classes.addNew}>
                                    <span onClick={() => { setShowIndustry(!showIndustry) }}>Add New</span>
                                </div>} */}
                            </div>
                            {/* {showIndustry ? <div>
                                <label>New Industry<span>*</span></label>
                                <input type="text" value={addIndustryValue} onChange={(e) => { setAddIndustryValue(e.target.value) }} />
                                <div className={classes.addNew}>
                                    <span onClick={() => { setShowIndustry(!showIndustry) }}>Add this Industry</span>
                                </div>
                            </div> : <></>} */}

                            <div>
                                <label>Company Description</label>
                                <textarea rows={"5"} value={compDesc} onChange={(e) => { setCompDesc(e.target.value) }} />
                            </div>
                            <div>
                                <label>Tel</label>
                                <input type="text" value={mobileCompany} onChange={(e) => { setMobileCompany(e.target.value) }} />
                            </div>
                            <div>
                                <label>Email</label>
                                <input type="email" value={emailCompany} onChange={(e) => { setEmailCompany(e.target.value) }} />
                            </div>
                            <div>
                                <label>Website</label>
                                <input type="text" value={websiteCompany} onChange={(e) => { setWebsiteCompany(e.target.value); }} />
                            </div>
                            <div>
                                <label>Address</label>
                                <textarea rows={"5"} value={addressCompany} onChange={(e) => { setAddressCompany(e.target.value) }} />
                            </div>



                            <div>
                                <label>Location<span>*</span></label>
                                <select value={selectedCompanyLoc} required onChange={(e) => { setSelectedCompanyLoc(e.target.value) }}>
                                    <option value="">Choose location</option>
                                    {
                                        props.locationList.map((item) => {
                                            return (
                                                <option value={item.id}>{item.locName}</option>
                                            )
                                        })
                                    }
                                </select>
                                {/* {showLocation ? <></> : <div className={classes.addNew}>
                                    <span onClick={() => { setShowLocation(!showLocation) }}>Add New</span>
                                </div>} */}
                            </div>

                            {/* {showLocation ? <div>
                                <label>New location mame</label>
                                <input type="text" value={addLocationValue} onChange={(e) => { setAddLocationValue(e.target.value) }} />
                                <div className={classes.addNew}>
                                    <span onClick={() => { setShowLocation(!showLocation) }}>Add this Location</span>
                                </div>
                            </div> : <></>} */}
                            <div>
                                <label>Company logo</label>
                                <input type="file" onChange={(e) => { setCompanyLogo(e.target.files[0]) }} />
                                {companyLogo || data.imagePath ? <img src={companyLogo ? URL.createObjectURL(companyLogo) : global.apiLink + "" + data.imagePath} height={200} /> : <></>}
                            </div>
                            <div className={classes.text_left}>
                                <label>Government Organization</label>
                                <input type="checkbox" onChange={(e) => { setGovernmentCompany(e.target.checked) }} />
                            </div>


                        </div>
                        <div className={classes.header}>
                            <h4>Job Posting Details</h4>
                            <ImCross onClick={handleClose} />
                        </div>
                        <div>
                            <div>
                                <label>Job Application Details</label>
                                <textarea rows={"5"} value={jobApplicationDetail} onChange={(e) => { setJobApplicationDetail(e.target.value) }} />
                            </div>
                            <div>
                                <label>Position Title<span>*</span></label>
                                <input type="text" value={positionTitle} onChange={(e) => { setPositionTitle(e.target.value) }} required />
                            </div>
                            <div>
                                <label>Job Function<span>*</span></label>
                                <select value={jobFunction} onChange={(e) => { setJobFunction(e.target.value) }} required>
                                    <option value="" disabled={true} selected>Choose the option</option>
                                    {
                                        props.jobFunctionList.map((item) => {
                                            return (
                                                <option value={item.id}>{item.jobName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div>
                                <label>Level<span>*</span></label>
                                <select value={level} onChange={(e) => { setLevel(e.target.value) }} required>
                                    <option value="" disabled={true}>Choose the option</option>
                                    {
                                        props.levelList.map((item) => {
                                            return (
                                                <option value={item.id}>{item.levelName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div>
                                <label>Job Description<span>*</span></label>
                                <textarea rows={"5"} value={jobDescription} onChange={(e) => { setJobDescription(e.target.value) }} required />
                            </div>
                            <div>
                                <label>Min Salary</label>
                                <input type="number" value={minSalary} onChange={(e) => { setMinSalary(e.target.value) }} />
                            </div>
                            <div>
                                <label>Max Salary</label>
                                <input type="number" value={maxSalary} onChange={(e) => { setMaxSalary(e.target.value) }} />
                            </div>
                            <div>
                                <label>Email</label>
                                <input type="email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                            </div>
                            <div>
                                <label>Industry<span>*</span></label>
                                <select value={industry} onChange={(e) => { setIndustry(e.target.value) }} required>
                                    <option value="">Choose the option</option>
                                    {
                                        props.industryList.map((item) => {
                                            return (
                                                <option value={item.id}>{item.industryName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div>
                                <label>Employment Type</label>
                                <select value={employementType} onChange={(e) => { setEmployementType(e.target.value) }} >
                                    <option value="" disabled={true} selected>Choose the option</option>
                                    {
                                        employmentList.map((item) => {
                                            return (
                                                <option value={item.type}>{item.type}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            {/* <div>
                                    <label>Postal Code<span>*</span></label>
                                    <input type="text" value={postalCode} onChange={(e) => { setPostalCode(e.target.value) }} required />
                                </div> */}
                            {/* <div>
                                    <label>Currency</label>
                                    <select value={currency} onChange={(e) => { setCurrency(e.target.value) }}>
                                        <option value="" disabled={true} >Choose the option</option>
                                        {
                                            curr.map((item) => {
                                                return (
                                                    <option value={item.cc}>{item.cc}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div> */}
                            {/* <div>
                                    <label>Street Address</label>
                                    <input type="text" value={streetAddress} onChange={(e) => { setStreetAddress(e.target.value) }} />
                                </div> */}
                            <div>
                                <label>Region<span>*</span></label>
                                <select value={region} onChange={(e) => { setRegion(e.target.value) }} required>
                                    <option value="" disabled={true} selected>Choose the option</option>
                                    {
                                        states.map((item) => {
                                            return <option key={item.id} value={item.id}>{item.code}-{item.name}</option>

                                        })
                                    }
                                </select>
                            </div>
                            <div>
                                <label>External Apply URL</label>
                                <input type="text" value={externalApplyUrl} onChange={(e) => { setExternalApplyUrl(e.target.value) }} />
                            </div>
                            <div>
                                <span className={classes.note}>(Please enter external URL from print ads if email is not present)</span>
                            </div>
                            <div>
                                <label>Locations<span>*</span></label>
                                <select onChange={(e) => { findLocationIdHandler(e.target.value); addLocationHandler(e.target.value); }}>
                                    <option value="" disabled={true} selected>Choose the option</option>
                                    {

                                        props.locationList.map((item, id) => {
                                            return <option key={item.id}>{item.locName}</option>
                                        })
                                    }
                                </select>

                            </div>


                            <div className={classes.formGroup} style={{ position: "relative" }}>
                                <label htmlFor="seoUrl">SEO URL:</label>
                                <input
                                    type="text"
                                    id="seoUrl"
                                    value={seoUrl}
                                    onChange={(e) => handleSeoUrlChange(e.target.value)}
                                    disabled={!isSeoUrlEditable}
                                    required
                                />
                                {seoUrlValidationMessage && (
                                    <div
                                        className={
                                            isSeoUrlValid
                                                ? classes.successMessage
                                                : classes.errorMessage
                                        }
                                    >
                                        {seoUrlValidationMessage}
                                    </div>
                                )}
                            </div>
                            <div className={classes.locationBoxWrapper}>
                                {selectedLocationsName.map((currentLocationValue, index) => {
                                    return (
                                        <div key={index} id={index} className={classes.locationBox}>
                                            {currentLocationValue}
                                            <AiOutlineClose
                                                onClick={() => {
                                                    deleteLocationName(index);
                                                    deleteLocationId(index);
                                                }}
                                                className={classes.closeIcon}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            <hr />
                            <div>
                                <h5>Walk in Details</h5>
                            </div>
                            <div>
                                <label>Start Time</label>
                                <input type="time" value={walkInStartTime} onChange={(e) => { setWalkInStartTime(e.target.value) }} />
                            </div>
                            <div>
                                <label>End Time</label>
                                <input type="time" value={walkInEndTime} onChange={(e) => { setWalkInEndTime(e.target.value) }} />
                            </div>
                            <div>
                                <label>Start Date</label>
                                <input type="date" value={startDate} onChange={(e) => { setStartDate(e.target.value) }} />
                            </div>
                            <div>
                                <label>End Date</label>
                                <input type="date" value={endDate} onChange={(e) => { setEndDate(e.target.value) }} />
                            </div>
                            <div>
                                <label>Address</label>
                                <textarea rows={"5"} value={address} onChange={(e) => { setAddress(e.target.value) }} />
                            </div>
                            <div className={classes.buttons}>
                                {loading ? <Loader type="TailSpin" color="red" height={30} width={30} />
                                    : <button type="submit">Save Job</button>}
                            </div>
                        </div>
                    </form>
                </div>}
            </Modal.Body>

        </Modal>
    </>;
};


export default ShowCompanyAndJobModal;
