import React, { useState } from "react";
import classes from "./AddAboutUs.module.css";
import EventInputs from "../Components/Events/EventInputs";
import Modal from "../Components/Modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";
import Loader from "react-loader-spinner";
import TextEditor from "../Components/TextEditor";

const AddAboutUs = () => {
    const [loading, setLoading] = useState(false);
    const [erorrshow, setErrorShow] = useState(false);
    const [internalErrorShow, setInternalErrorShow] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [Title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [pageName, setPageName] = useState("");

    let history = useHistory();

    const pageList = [
        { type: "LeadersOfChange" },
        { type: "Blogs" },
        { type: "Home" },
        { type: "Global" },
        { type: "ForHer" },
        { type: "GMI" },
        { type: "HrConclave" },
        { type: "SHEro" },
        { type: "LeadersConclave" },
        { type: "HRProfessionals" },
        { type: "ActOfCompassion" },
        { type: "Events" },
        { type: "RecruitersIpEdge" },
    ]

    const AddAboutPage = () => {
        setLoading(true);
        const myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 4YzpGk8Vf8IcxzxQ68teZ2KM5iX8SktW5RN4iz92"
        );
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            Title: Title,
            Description: description,
            PageName: pageName,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_API_URL + "/adminapi/InsertAboutUs",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "Success" && result.status_code === 200) {
                    setModalShow(true);
                    setDescription("");
                } else if (result.status_code === 400 || result.status_code === 500) {
                    setInternalErrorShow(true);
                } else if (result.status_code === 300) {
                    setErrorShow(true);
                }
            })
            .catch((error) => console.log("error", error))
            .finally(() => setLoading(false));
    };

    const pageInfo = {
        currentPageName: "Add about page",
        previousPageName: "About List",
    };
    return (
        <div className={classes.top_div}>
            {modalShow && (
                <Modal
                    heading={"About Page added successfully"}
                    onClick={() => history.push("/BiDashboard/AboutPageList")}
                />
            )}
            {erorrshow && (
                <Modal
                    heading={"Required Fields Are Missing"}
                    onClick={() => setErrorShow(false)}
                />
            )}
            {internalErrorShow && (
                <Modal
                    heading={"Internal server error"}
                    onClick={() => setInternalErrorShow(false)}
                />
            )}

            <div className={classes.container}>
                <div className={classes.form}>
                    <div className={classes.TimsbiBreadcrumb}>
                        {" "}
                        <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
                    </div>
                    <div className={classes.form_header_container}>
                        <div className={classes.form_header}>Add About Page</div>
                    </div>
                    <div className={classes.form_input}>
                        <div className={classes.input}>
                            <EventInputs
                                input={"SingleLineInput"}
                                label={"Title"}
                                placeholder={"Title"}
                                onChange={(e) => setTitle(e.target.value)}
                                value={Title}
                            />

                            <EventInputs
                                input={"smalldropdown"}
                                label={"Page Name"}
                                placeholder={"Page Name"}
                                onChange={(e) => setPageName(e.target.value)}
                                displayData={pageList}
                                value={pageName}
                            />
                        </div>
                        <div className={classes.formGroup}>
                            <label htmlFor="Content">Description :</label>

                            <div>
                                <TextEditor
                                    onChangeContent={setDescription}
                                    value={description}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <button className={classes.Upload_button} onClick={() => AddAboutPage()}>
                    {loading ? (
                        <Loader
                            type="TailSpin"
                            color="white"
                            height={20}
                            width={50}
                            className="text-center"
                        />
                    ) : (
                        "Add"
                    )}
                </button>
            </div>
        </div >
    );
};

export default AddAboutUs;
