import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";
import SignInWithGoogle from "../Components/SignInWithGoogle";
import classes1 from "./MainRoute.module.css";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import Dashboard from "../Screen/Dashboard";
import classes from "./Routing.module.css";
import PartnerCompanies from "../Screen/PartnerCompanies";
import Orders from "../Screen/Orders";
import AddEvents from "../Components/Events/AddEvents";
import AddOffers from "../Components/Events/AddOffers";
import AddYoutubeVideo from "../Components/Events/AddYoutubeVideo";
import EditOffer from "../Components/Events/EditOffer";
import EditYoutubeVideo from "../Components/Events/EditYoutubeVideo";
import InterstitialAds from "../Screen/InterstitialAds";
import Events from "../Screen/Events";
import ForherOffers from "../Screen/ForherOffers";
import YoutubeVideo from "../Screen/YoutubeVideo";
import EventDetail from "../Components/Events/EventDetail";
import UpdateEvent from "../Components/Events/UpdateEvent";
import Cources from "../Screen/Courses";
import CourseDetail from "../Components/Courses/CourseDetails";
import AddCourses from "../Components/Courses/AddCourses";
import UpdateCourse from "../Components/Courses/UpdateCourse";
import HrProfessional from "../Screen/HrProfessional";
import HrProfessionalDetails from "../Components/HrProfessional/HrProfessionalDetails";
import AddHrProfessional from "../Components/HrProfessional/AddHrProfessional";
import UpdateHr from "../Components/HrProfessional/UpdateHr";
import FeaturedCompaniesDetails from "../Components/FeaturedCompanies/FeaturedCompaniesDetails";
import AddFeaturedCompanies from "../Components/FeaturedCompanies/AddFeaturedCompanies";
import UpdateFeaturedCompanies from "../Components/FeaturedCompanies/UpdateFeaturedCompanies";
import { BiError } from "react-icons/bi";
import Articles from "../Screen/Articles";
import NewArticle from "../Screen/NewArticle";
import ViewArticle from "../Screen/ViewArticle";
import EditArticle from "../Screen/EditArticle";
import PostAJob from "../Screen/PostAJob";
import RecruiterSignUp from "../Screen/RecruiterSignUp";
import PostAJobs from "../Screen/PostAJobs";
import AdvertiseNowDetails from "../Screen/AdvertiseNowDetails";
import ContactUsDetails from "../Screen/ContactUsDetails";
import RecruiterSignupDet from "../Screen/RecruiterSignupDet";
import RecruiterSignupMonthlty from "../Screen/RecruiterSignupMonthlty";
import OnlineJobs from "../Screen/OnlineJobs";
import OnlineJobMonthly from "../Screen/OnlineJobMonthly";
import EasyApplyToday from "../Screen/EasyApplyToday";
import EasyApplyMonthly from "../Screen/EasyAppyMonthly";
import PaymentSuccToday from "../Screen/PaymentSuccToday";
import PaymentSuccMonthly from "../Screen/PaymentSuccMonthly";
import PaymentFailToday from "../Screen/PaymentFailToday";
import PaymentFailMonthly from "../Screen/PaymentFailMonthly";
// import PostAJobForm from "../Components/Events/PostAJobForm";
// --------------------------TimespaperAds----------------------
import CreateCompany from "../TimesPaperAds/Component/CreateCompany";
import JobPostingDetail from "../TimesPaperAds/Screens/JobPostingDetail";
import GoogleSignIn from "../TimesPaperAds/Component/GoogleSignIn";
import DashNav from "../TimesPaperAds/Component/DashNav";
// import Header from '../TimesPaperAds/Component/Header';

import AdminAdsEntry from "../TimesPaperAds/Screens/AdminAdsEntry";
import RecruiterDetails from "../TimesPaperAds/Screens/RecruiterDetails";
import Recruiters from "../TimesPaperAds/Screens/Recruiters";
import JobDetails from "../TimesPaperAds/Screens/JobDetails";
import OrderDetails from "../TimesPaperAds/Screens/OrderDetails";
import Inbox from "../TimesPaperAds/Screens/Inbox";
import AdsQc from "../TimesPaperAds/Screens/AdsQc";
import NewJobAds from "../TimesPaperAds/Screens/NewJobAds";
import Reports from "../TimesPaperAds/Screens/Reports";
import Qcform from "../TimesPaperAds/Component/Qcform";
import Editform from "../TimesPaperAds/Component/Editform";
import JobAds from "../TimesPaperAds/Component/JobAds";
import DashJobList from "../TimesPaperAds/Component/DashJobList";

import TOIWidget from "../Screen/TOIWidget/TOIWidget";
import TOIWidgetList from "../Screen/TOIWidget/TOIWidgetList";
import UpdateTOIWidget from "../Screen/TOIWidget/UpdateTOIWidget";
import InterstitialAd from "../Screen/InterstitialAd";

import GridDashboard from "../NewJobAdmin/Screens/GridDashboard";
import BiHeaderNew from "../NewJobAdmin/Components/BiHeader";

import FetchAds from "../NewJobAdmin/Screens/FetchAds";
import FetchAdsInbox from "../NewJobAdmin/Screens/FetchAdsInbox";
import QCList from "../NewJobAdmin/Screens/QCList";
import Draft from "../NewJobAdmin/Screens/Drafts";
import RecruiterDetailsNew from "../NewJobAdmin/Screens/RecruiterDetails";
import PrintAdsLiveJobs from "../NewJobAdmin/Screens/PrintAdsLiveJobs";
import NonAscentJobsAdsList from "../NewJobAdmin/Screens/NonAscentJobsAdsList";
import NonAscentJobsQCList from "../NewJobAdmin/Screens/NonAscentJobsQCList";
import NonAscentJobsLiveJobs from "../NewJobAdmin/Screens/NonAscentJobsLiveJobs";
import FeaturedCompanyList from "../Screen/FeaturedCompany/FeaturedCompanyList";
import FeaturedCompanies from "../Screen/FeaturedCompany/FeaturedCompanies";
import LeadershipFactoryList from "../Screen/LeadershipFactory/LeadershipFactoryList";
import LeadershipFactoryInsert from "../Screen/LeadershipFactory/LeadershipFactoryInsert";
import PostAJobsAdsList from "../NewJobAdmin/Screens/PostAJobsAdsList";
import PostaJobsLiveJobs from "../NewJobAdmin/Screens/PostaJobsLiveJobs";
import PivotTable from "../Screen/PivotTable/PivotTable";
import DataPoints from "../Components/DataPoints";

import SignupLastMonth from "../DataPoints/SignupLastMonth";
import ResumeUploadLastMonth from "../DataPoints/ResumeUploadLastMonth";
import EasyApplyDataPointsToday from "../DataPoints/EasyApplyDataPointsToday";
import RedictionPartners from "../DataPoints/RedictionPartners";
import AdminDataPoints from "../DataPoints/AdminDataPoints";
import FeaturedEmployerDetail from "../DataPoints/FeaturedEmployerDetail";
import SignupLastMonthDetail from "../DataPoints/SignupLastMonthDetail";
import ResumeUploadsLastMonthDetail from "../DataPoints/ResumeUploadsLastMonthDetail";
import CourseDataPoints from "../DataPoints/CourseDataPoints";
import CoursedataDetails from "../DataPoints/CoursedataDetails";
import RecruiterLoginDataPoints from "../DataPoints/RecruiterLoginDataPoints";
import RecruiterLoginDetail from "../DataPoints/RecruiterLoginDetail";
import LoginDataPoints from "../DataPoints/LoginDataPoints";
import Logindatadetail from "../DataPoints/Logindatadetail";
import ArticleDataPoints from "../DataPoints/ArticleDataPoint";
import ArticleDetail from "../DataPoints/ArticleDetail";
import ImpressionWidget from "../DataPoints/ImpressionWidget";
import ImpressionDetail from "../DataPoints/ImpressionDetail";
import EasyApplyJobDetail from "../DataPoints/EasyApplyJobDetail";
import RedirectionDetail from "../DataPoints/RedirectionDetail";
import RedirectionPartnerDate from "../DataPoints/RedirectionPartnerDate";
import GetListofUserspostingJob from "../Components/GetListofUserspostingJob";
import EasyApplydetail from "../DataPoints/EasyApplydetail";
import LandingPage from "../Components/LandingPage";
import LandingPagedetails from "../Components/LandingPagedetails";
import CourseById from "../DataPoints/CourseById";
import FeaturedCompanyDetail from "../DataPoints/FeaturedCompanyDetail";
import EasyApplyThroghId from "../DataPoints/EasyApplyThroghId";
import Mentorkart from "../Components/Mentorkart";
import FeaturedApplyresume from "../Components/FeaturedApplyresume";
import PollsList from "../Components/PostanPoll/PollsList";
import CreatePoll from "../Components/PostanPoll/CreatePoll";
import UpdateApoll from "../Components/PostanPoll/UpdateApoll";
import EventRegisterList from "../Components/EventRegisterList.js";
import EventRegisterDetail from "../Components/EventRegisterDetail";
import ImageList from "../Screen/BannerCarousel/ImageList";
import Insertbanner from "../Screen/BannerCarousel/Insertbanner";
import UpdateImage from "../Screen/BannerCarousel/UpdateImage";
import WidgetList from "../Components/TimesWidget/WidgetList";
import CreateWidget from "../Components/TimesWidget/CreateWidget";
import EditWidget from "../Components/TimesWidget/EditWidget";
import GuestList from "../Components/GuestArticle/GuestList";
import AddGuest from "../Components/GuestArticle/AddGuest";
import EditGuestArticle from "../Components/GuestArticle/EditGuestArticle";
import Wecarelist from "../Components/WeCare/Wecarelist";
import CorporateCareList from "../Components/WeCare/CorporateCareList";
import AddWecare from "../Components/WeCare/AddWecare";
import EditWecare from "../Components/WeCare/EditWecare";
import ViewDetail from "../Components/WeCare/ViewDetail";
import MovementList from "../Components/Movement Of Professional/MovementList";
import MovementAdd from "../Components/Movement Of Professional/MovementAdd";
import MovementEdit from "../Components/Movement Of Professional/MovementEdit";
import MovementView from "../Components/Movement Of Professional/MovementView";
import WeCareMopList from "../Components/WeCare/WeCareMopList";
import WeCareMopFormDetail from "../Components/WeCare/WeCareMopFormDetail";
import Candidate from "../Components/Candidate";
import ForumList from "../Components/Forum/ForumList";
import WebstoryList from "../Components/Webstory/WebstoryList";
import AddWebstory from "../Components/Webstory/AddWebstory";
import AddWebstorySummary from "../Components/Webstory/AddWebstorySummary";
import Webstorydetail from "../Components/Webstory/Webstorydetail";
import Webstoryupdate from "../Components/Webstory/Webstoryupdate";
import WebstoryDeatilUpdate from "../Components/Webstory/WebstoryDeatilUpdate";
import HrshareFormlist from "../Components/HrshareFormlist";
import Advertise from "../Components/Advertisewithus/Advertise";
import CourseList from "../Components/Courses/CourseList";
import MopJourney from "../Components/MopJourney";
import ReportBug from "../Components/Advertisewithus/ReportBug";
import WriteForUsList from "../Components/WeCare/WriteForUsList";
import AddForherById from "../Components/forherById/AddForherById";
import EventList from "../Components/EventList";
import NewAdmin from "../Components/WeCare/NewAdmin";
import ForherPostList from "../Components/GuestArticle/ForherPostList";
import ForherPostEdit from "../Components/GuestArticle/ForherPostEdit";
import Podcasts from "../Screen/Podcasts/Podcasts";
import AddPodcast from "../Screen/Podcasts/AddPodcast";
import UpdatePodcast from "../Screen/Podcasts/UpdatePodcast";
import JunkList from "../NewJobAdmin/Screens/JunkList";
import GreatManagerInstitute from "../Screen/GMI/GMI";
import AddGreatManagerInstitute from "../Screen/GMI/AddGMI";
import UpdateGreatManagerInstitute from "../Screen/GMI/UpdateGMI";
import EditLeadershipFactory from "../Screen/LeadershipFactory/LeadershipFactoryEdit";
import NominateYourManager from "../Screen/NominateYourManager/NominateYourManagerList";
import LoginData from "../Screen/UserLoginData/LoginData";
import ArticlesDownload from "../Screen/FTPArticles/ArticlesDownload";
import LottieFileList from "../Screen/LottieFiles/LottieFileList";
import InsertLottieFile from "../Screen/LottieFiles/InsertLottieFile";
import UpdateLottieFile from "../Screen/LottieFiles/UpdateLottieFile";
import CertificationOfWinners from "../Screen/CertificationOfWinners/CertificationOfWinners";
import AddCertificationOfWinners from "../Screen/CertificationOfWinners/AddCertificationOfWinners";
import EditCertificationOfWinners from "../Screen/CertificationOfWinners/EditCertificationOfWinners";
import LeadersOfChange from "../Screen/LeadersOfChange/LeadersOfChange";
import AddLeadersOfChange from "../Screen/LeadersOfChange/AddLeadersOfChange";
import EditLeadersOfChange from "../Screen/LeadersOfChange/EditLeadersOfChange";
import JobsList from "../Screen/FeaturedCompany/JobsList";
import InspectionUrl from "../Screen/GoogleIndexing/InspectionUrl.js";
import DeleteUrl from "../Screen/GoogleIndexing/DeleteUrl.js";
import CheckeUrl from "../Screen/GoogleIndexing/CheckUrl.js";
import CheckUrl from "../Screen/GoogleIndexing/CheckUrl.js";
import HomeYouTubeVideos from "../HomeYouTubeVideos/HomeYouTubeVideos.js";
import AddVideo from "../HomeYouTubeVideos/AddVideo.js";
import WebinarVideos from "../Webinarvideos/WebinarVideos.js";
import InsertVideo from "../Webinarvideos/InsertVideo.js";
import ActOfCompassionList from "../Components/ActOfCompassion/ActOfCompassionList.js";
import YoutubeVideos from "../Webinarvideos/YoutubeVideos.js";
import FAQ from "../Webinarvideos/FAQ.js";
import FaqInsert from "../Webinarvideos/FaqInsert.js";
import UpdateFaq from "../Webinarvideos/UpdateFaq.js";
import WealthCourse from "../Webinarvideos/WealthCourse.js";
import InsertCourse from "../Webinarvideos/InsertCourse.js";

import WealthEvent from "../Webinarvideos/WealthEvent.js";
import WealthArticle from "../Webinarvideos/WealthArticle.js";

import EditEvent from "../Webinarvideos/EditEvent.js";
import EditCourse from "../Webinarvideos/EditCourse.js";
import InsertWealthAricle from "../Webinarvideos/InsertWealthAricle.js";
import EventForm from "../Webinarvideos/EventForm.js";
import WebinarForm from "../Webinarvideos/WebinarForm.js";
import CompanyLogo from "../Webinarvideos/CompanyLogo.js";
import CompanyLogoInsert from "../Webinarvideos/newCompanyLogo.js";
import EditCompanyLogo from "../Webinarvideos/EditCompanyLogo.js";
import UpdatePageJson from "../UpdateJson/UpdatePageJson.js";
import Mailer from "../Mailer/Mailer.js";
import Eventregistered from "../Mailer/Eventregistered.js";
import Hrconclave from "../HrConclave/HrConclave.js";
import AddHrConclave from "../HrConclave/AddHrConclave.js";
import HrVideos from "../HrConclave/HrVideos.js";
import AddHrVideo from "../HrConclave/AddHrVideo.js";
import HrAbout from "../HrConclave/HrAbout.js";
import AddAbout from "../HrConclave/AddAbout.js";
import EditHrconclave from "../HrConclave/EditHrconclave.js";
//import Jobwebstory from "../NewJobAdmin/Screens/Jobwebstory.js";
import SheroVideo from "../SHreo/SheroVideo.js";
import AddSheroVideos from "../SHreo/AddSheroVideos.js";
import SheroWebinarVideos from "../SHreo/SheroWebinarVideos.js";
import SheroCompanyLogo from "../SHreo/SheroCompanyLogo.js";
import AddSheroCompanyLogo from "../SHreo/AddSheroCompanyLogo.js";
import SheroEvent from "../SHreo/SheroEvent.js";
import InsertSheroEvent from "../SHreo/InsertSheroEvent.js";
import PodcastVideo from "../Screen/Podcasts/PodcastVideos.js";
import PodcastVideos from "../Screen/Podcasts/PodcastVideos.js";
import AddPodcastVideo from "../Screen/Podcasts/AddPodcastVideo.js";
import ArticleManager from "../Screen/ArticleManager/ArticleManager.js";
import AddArticleManager from "../Screen/ArticleManager/AddArticleManager.js";
import UpdateManager from "../Screen/ArticleManager/UpdateManager.js";
import Preview from "../Components/Webstory/preview.js";
import TestimonialsList from "../Components/Global/TestimonialsList.js";
import GlobalCompanyLogo from "../Components/Global/GlobalCompanyLogo.js";
import CreateTestimonials from "../Components/Global/CreateTestimonials.js";
import EditTestimonials from "../Components/Global/EditTestimonials.js";
import GlobalEditCompanyLogo from "../Components/Global/GlobalEditCompanyLogo.js";
import GlobalInsertCompanyLogo from "../Components/Global/GlobalInsertCompanyLogo.js";
import ArticlePreview from "../Components/ArticlePreview.js";
import PageWiseListWebstory from "../Components/Webstory/PageWise/PageWiseWebstory.js";
import AddPagewise from "../Components/Webstory/PageWise/AddPageWiseWebstory.js";
import FeaturedHRList from "../Components/HrProfessional/FeaturedHRList.js";
import AddFeaturedHR from "../Components/HrProfessional/AddFeaturedHR.js";
import StudentInformation from "../Components/Global/StudentInformation.js";
import CompanyInformation from "../Components/Global/CompanyInformation.js";
import QuizCategoreisList from "../Components/Quiz/QuizCategoreisList.js";
import AddCategoreis from "../Components/Quiz/AddCategoreis.js";
import QuizList from "../Components/Quiz/QuizList.js";
import AddQuizSection from "../Components/Quiz/AddQuizSection.js";
import EditQuizSection from "../Components/Quiz/EditQuizSection.js";
import ConnectGlobal from "../Components/Global/ConnectGlobal.js";
import QuizQuestionList from "../Components/Quiz/QuestionList.js";
import AddQuestion from "../Components/Quiz/AddQuestion.js";
import EditQuestion from "../Components/Quiz/EditQuestion.js";
import AddGlobalJobs from "../Components/Global/AddGlobalJobs.js";
import Questiondetails from "../Components/Quiz/Questiondetails.js";
import QuizPlayers from "../Components/Quiz/QuizPlayers.js";
import CustomJobContentList from "../Components/CustomJobContent/CustomJobContentList.js";
import AddCustomJobContent from "../Components/CustomJobContent/AddCustomJobContent.js";
import EditCustomJobContent from "../Components/CustomJobContent/EditCustomJobContent.js";
import PageWiseEventsList from "../Components/Events/PageWise/PageWiseEventsList.js";
import AddPageWiseEvents from "../Components/Events/PageWise/AddPageWiseEvents.js";
import LiveWebinarRecruiters from "../RecruiterEdge/LiveWebinarRecruiters.js";
import AddLiveWebinarRecruiters from "../RecruiterEdge/AddLiveWebinarRecruiters.js";
import AddAboutUs from "../About/AddAboutUs.js";
import AboutPageList from "../About/AboutPageList.js";
import KeyWordList from "../Keywords/KeyWordList.js";
import AddSkill from "../Keywords/AddSkill.js";
import AddKeyWord from "../Keywords/AddKeyWord.js";
import List from "../candidateList/List.js";
import CompanyList from "../Company/CompanyList.js";
import JobList from "../Company/JobList.js";
import EditJobs from "../Company/EditJobs.js";
import CompanyView from "../Company/CompanyView.js";


// import UpdatePageJson from "../Components/UpdateJson/UpdatePageJson.js";

const MainRoute = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState();
  const [accessLevel, setAccessLevel] = useState();
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes("/BiDashboard/preview")) {
      return;
    }
    if (sessionStorage.getItem("Token")) {
      biAuthCheck(sessionStorage.getItem("Token"));
    } else {
      history.push("/BiDashboard/SignInWithGoogle");
    }
  }, [sessionStorage.getItem("Token")]);

  const biAuthCheck = (token) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Token: token,
      RequestUrl: "url",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL +
      "/v1/api/apiTimes/BIAdminAutheri",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "SUCCESS") {
          setAccessLevel(result.data.accessLevel);
          sessionStorage.setItem("level", result.data.accessLevel);
          console.log(result.data.accessLevel);
        } else {
          setError(true);
        }
      })
      .catch((error) => console.log("error", error));
  };

  if (pathname.includes("/BiDashboard/preview")) {
    return (
      <Route
        path="/BiDashboard/preview/articles/:title/:id?"
        component={ArticlePreview}
      />
    );
  }


  return (
    <div className={classes1.container}>
      <div className={classes.web_view}>
        <Route
          path="/BiDashboard/SignInWithGoogle"
          component={SignInWithGoogle}
        />
        <div className={classes.main_container}>
          <Redirection setEmail={setEmail} biAuthCheck={biAuthCheck} />
          <div>
            {accessLevel === "SAS" ? (
              <>
                <Header userType={"SAS"} />
                <div className={classes.container}>
                  <Sidebar userType={"SAS"} />
                  <Switch>
                    <Route path="/BiDashboard/CustomJobContent">
                      <CustomJobContentList />
                    </Route>
                    <Route path="/BiDashboard/AddCustomJobContent">
                      <AddCustomJobContent />
                    </Route>
                    <Route path="/BiDashboard/EditCustomJobContent/:id">
                      <EditCustomJobContent />
                    </Route>
                    <Route path="/BiDashboard/ShareHrprofilelist">
                      <HrshareFormlist />
                    </Route>
                    <Route path="/BiDashboard/advertisewithus">
                      <Advertise />
                    </Route>

                    <Route path="/BiDashboard/preview">
                      <Preview />
                    </Route>

                    <Route path="/BiDashboard/courses-list">
                      <CourseList />
                    </Route>
                    <Route path="/BiDashboard/inspectionUrl-list">
                      <InspectionUrl />
                    </Route>
                    <Route path="/BiDashboard/AddGuestArticle">
                      <AddGuest />
                    </Route>

                    <Route path="/BiDashboard/CompanyList">
                      <CompanyList />
                    </Route>

                    <Route path="/BiDashboard/JobList">
                      <JobList />
                    </Route>

                    <Route path="/BiDashboard/EditJobs/:id">
                      <EditJobs />
                    </Route >

                    <Route path="/BiDashboard/CompanyView/:id">
                      <CompanyView />
                    </Route>


                    <Route path="/BiDashboard/deleteUrl-list">
                      <DeleteUrl />
                    </Route>
                    <Route path="/BiDashboard/checkUrl-list">
                      <CheckUrl />
                    </Route>
                    <Route path="/BiDashboard/report-a-bug">
                      <ReportBug />
                    </Route>
                    <Route path="/BiDashboard/eventList">
                      <EventList />
                    </Route>
                    <Route path="/BiDashboard/MopJourney">
                      <MopJourney />
                    </Route>
                    <Route path="/BiDashboard/forumlist">
                      <ForumList />
                    </Route>

                    <Route path="/BiDashboard/List">
                      <List />
                    </Route >

                    <Route path="/BiDashboard/KeyWordList">
                      <KeyWordList />
                    </Route>

                    <Route path="/BiDashboard/AddKeyWord">
                      <AddKeyWord />
                    </Route>

                    <Route path="/BiDashboard/AddSkill/:id">
                      <AddSkill />
                    </Route>

                    <Route path="/BiDashboard/TestimonialsList">
                      <TestimonialsList />
                    </Route>

                    <Route path="/BiDashboard/QuizList">
                      <QuizList />
                    </Route>

                    <Route path="/BiDashboard/AddCategoreis">
                      <AddCategoreis />
                    </Route>

                    <Route path="/BiDashboard/AddQuizSection">
                      <AddQuizSection />
                    </Route>

                    <Route path="/BiDashboard/EditQuizSection/:id">
                      <EditQuizSection />
                    </Route>


                    <Route path="/BiDashboard/QuizQuestionList">
                      <QuizQuestionList />
                    </Route>
                    <Route path="/BiDashboard/AddQuizQuestion">
                      <AddQuestion />
                    </Route>
                    <Route path="/BiDashboard/editQuizQuestion/:id">
                      <EditQuestion />
                    </Route>


                    <Route path="/BiDashboard/QuizCategoreisList">
                      <QuizCategoreisList />
                    </Route>
                    <Route path="/BiDashboard/QuizPlayers">
                      <QuizPlayers />
                    </Route>
                    <Route path="/BiDashboard/AddGlobalJobs">
                      <AddGlobalJobs />
                    </Route>


                    <Route path="/BiDashboard/GlobalCompanyLogo">
                      <GlobalCompanyLogo />
                    </Route>

                    <Route path="/BiDashboard/CompanyInformation">
                      <CompanyInformation />
                    </Route>

                    <Route path="/BiDashboard/StudentInformation">
                      <StudentInformation />
                    </Route>


                    <Route path="/BiDashboard/GlobalConnectInfo">
                      <ConnectGlobal />
                    </Route>

                    <Route path="/BiDashboard/CreateTestimonials">
                      <CreateTestimonials />
                    </Route>

                    <Route path="/BiDashboard/EditTestimonials/:Id">
                      <EditTestimonials />
                    </Route>

                    <Route path="/BiDashboard/GlobalEditCompanyLogo/:id">
                      <GlobalEditCompanyLogo />
                    </Route>

                    <Route path="/BiDashboard/GlobalInsertCompanyLogo">
                      <GlobalInsertCompanyLogo />
                    </Route>

                    <Route path="/BiDashboard/SheroVideo">
                      <SheroVideo />
                    </Route>

                    <Route path="/BiDashboard/AddSheroVideos">
                      <AddSheroVideos />
                    </Route>

                    <Route path="/BiDashboard/SheroWebinarVideos">
                      <SheroWebinarVideos />
                    </Route>

                    <Route path="/BiDashboard/AboutPageList">
                      <AboutPageList />
                    </Route>

                    <Route path="/BiDashboard/AddAboutUs">
                      <AddAboutUs />
                    </Route>

                    <Route path="/BiDashboard/LiveWebinar">
                      <LiveWebinarRecruiters />
                    </Route>

                    <Route path="/BiDashboard/AddLiveWebinarRecruiters">
                      <AddLiveWebinarRecruiters />
                    </Route>

                    <Route path="/BiDashboard/AddSheroCompanyLogo">
                      <AddSheroCompanyLogo />
                    </Route>

                    <Route path="/BiDashboard/InsertSheroEvent">
                      <InsertSheroEvent />
                    </Route>

                    <Route path="/BiDashboard/SheroEvent">
                      <SheroEvent />
                    </Route>

                    <Route path="/BiDashboard/SheroCompanyLogo">
                      <SheroCompanyLogo />
                    </Route>

                    <Route path="/BiDashboard/ActOfCompassionList">
                      <ActOfCompassionList />
                    </Route>

                    <Route path="/BiDashboard/Mailer">
                      <Mailer />
                    </Route>

                    <Route path="/BiDashboard/AddHrConclave">
                      <AddHrConclave />
                    </Route>

                    <Route path="/BiDashboard/HrVideos">
                      <HrVideos />
                    </Route>

                    <Route path="/BiDashboard/AddAbout">
                      <AddAbout />
                    </Route>

                    <Route path="/BiDashboard/HrAbout">
                      <HrAbout />
                    </Route>

                    <Route path="/BiDashboard/AddHrVideo">
                      <AddHrVideo />
                    </Route>

                    <Route path="/BiDashboard/Hrconclave">
                      <Hrconclave />
                    </Route>

                    <Route path="/BiDashboard/Eventregistered">
                      <Eventregistered />
                    </Route>
                    <Route path="/BiDashboard/HomeYouTubeVideos">
                      <HomeYouTubeVideos />
                    </Route>

                    <Route path="/BiDashboard/WebinarVideos">
                      <WebinarVideos />
                    </Route>

                    <Route path="/BiDashboard/WealthCourse">
                      <WealthCourse />
                    </Route>
                    <Route path="/BiDashboard/WealthEvent">
                      <WealthEvent />
                    </Route>

                    <Route path="/BiDashboard/WealthArticle">
                      <WealthArticle />
                    </Route>

                    <Route path="/BiDashboard/YoutubeVideos">
                      <YoutubeVideos />
                    </Route>

                    <Route path="/BiDashboard/FAQ">
                      <FAQ />
                    </Route>
                    <Route path="/BiDashboard/CompanyLogo">
                      <CompanyLogo />
                    </Route>
                    <Route path="/BiDashboard/newCompanyLogo">
                      <CompanyLogoInsert />
                    </Route>
                    <Route path="/BiDashboard/editCompanyLogo/:id">
                      <EditCompanyLogo />
                    </Route>
                    <Route path="/BiDashboard/EditHrconclave/:id">
                      <EditHrconclave />
                    </Route>

                    <Route path="/BiDashboard/EventForm">
                      <EventForm />
                    </Route>

                    <Route path="/BiDashboard/WebinarForm">
                      <WebinarForm />
                    </Route>

                    <Route path="/BiDashboard/webstorylist">
                      <WebstoryList />
                    </Route>
                    <Route path="/BiDashboard/PageWiseWebstory">
                      <PageWiseListWebstory />
                    </Route>
                    <Route path="/BiDashboard/AddPageWiseWebstory">
                      <AddPagewise />
                    </Route>
                    <Route path="/BiDashboard/PageWiseEvents">
                      <PageWiseEventsList />
                    </Route>
                    <Route path="/BiDashboard/AddPageWiseEvents">
                      <AddPageWiseEvents />
                    </Route>
                    <Route path="/BiDashboard/PageWiseFeaturedHR">
                      <FeaturedHRList />
                    </Route>
                    <Route path="/BiDashboard/AddPageWiseFeaturedHR">
                      <AddFeaturedHR />
                    </Route>

                    <Route path="/BiDashboard/Webstorydetail/:id">
                      <Webstorydetail />
                    </Route>

                    <Route path="/BiDashboard/Questiondetails/:id">
                      <Questiondetails />
                    </Route>

                    <Route path="/BiDashboard/Webstoryupdate/:id">
                      <Webstoryupdate />
                    </Route>
                    <Route path="/BiDashboard/updatewebstorydetail/:id">
                      <WebstoryDeatilUpdate />
                    </Route>
                    <Route path="/BiDashboard/AddWebstory">
                      <AddWebstory />
                    </Route>
                    <Route path="/BiDashboard/AddWebstorySummary/:id">
                      <AddWebstorySummary />
                    </Route>
                    <Route path="/BiDashboard/corporates-care">
                      <Wecarelist />
                    </Route>
                    <Route path="/BiDashboard/coporate-care-requests">
                      <CorporateCareList />
                    </Route>
                    <Route path="/BiDashboard/write-for-us-list">
                      <WriteForUsList />
                    </Route>
                    <Route path="/BiDashboard/CandidateResumeList">
                      <Candidate />
                    </Route>
                    <Route path="/BiDashboard/WeCareMopFormList">
                      <WeCareMopList />
                    </Route>
                    <Route path="/BiDashboard/WeCareMopFormDetail/:id">
                      <WeCareMopFormDetail />
                    </Route>
                    <Route path="/BiDashboard/Addcorporates-care">
                      <AddWecare />
                    </Route>
                    <Route path="/BiDashboard/NewAddCorporate">
                      <NewAdmin />
                    </Route>
                    <Route path="/BiDashboard/Editcorporates-care/:id">
                      <EditWecare />
                    </Route>
                    <Route path="/BiDashboard/ViewDetail/:id">
                      <ViewDetail />
                    </Route>
                    <Route path="/BiDashboard/MovementList">
                      <MovementList />
                    </Route>
                    <Route path="/BiDashboard/AddMovement">
                      <MovementAdd />
                    </Route>
                    <Route path="/BiDashboard/EditMovement/:id">
                      <MovementEdit />
                    </Route>
                    <Route path="/BiDashboard/Movementdetail/:id">
                      <MovementView />
                    </Route>

                    <Route path="/BiDashboard/PivotTable">
                      <PivotTable />
                    </Route>
                    <Route path="/BiDashboard/AddVideo">
                      <AddVideo />
                    </Route>
                    <Route path="/BiDashboard/UpdateFaq/:id">
                      <UpdateFaq />
                    </Route>

                    <Route path="/BiDashboard/FaqInsert">
                      <FaqInsert />
                    </Route>

                    <Route path="/BiDashboard/InsertWealthAricle">
                      <InsertWealthAricle />
                    </Route>

                    <Route path="/BiDashboard/InsertCourse">
                      <InsertCourse />
                    </Route>
                    <Route path="/BiDashboard/InsertVideo">
                      <InsertVideo />
                    </Route>
                    <Route path="/BiDashboard/GuestArticleList">
                      <GuestList />
                    </Route>
                    <Route path="/BiDashboard/EditGuestArticle/:id">
                      <EditGuestArticle />
                    </Route>
                    <Route path="/BiDashboard/forherPostEdit/:id/:pagename">
                      <ForherPostEdit />
                    </Route>
                    <Route path="/BiDashboard/dynamicwidget">
                      <WidgetList />
                    </Route>
                    <Route path="/BiDashboard/CreateWidget">
                      <CreateWidget />
                    </Route>
                    <Route path="/BiDashboard/EditWidget/:id">
                      <EditWidget />
                    </Route>
                    <Route path="/BiDashboard/PollsList">
                      <PollsList />
                    </Route>
                    <Route path="/BiDashboard/CreatePoll">
                      <CreatePoll />
                    </Route>
                    <Route path="/BiDashboard/UpdatePolls/:id">
                      <UpdateApoll />
                    </Route>
                    <Route path="/BiDashboard/BannerImageList">
                      <ImageList />
                    </Route>
                    <Route path="/BiDashboard/Insertbanner">
                      <Insertbanner />
                    </Route>
                    <Route path="/BiDashboard/UpdateImage/:id">
                      <UpdateImage />
                    </Route>
                    <Route path="/BiDashboard/LottieJsonList">
                      <LottieFileList />
                    </Route>
                    <Route path="/BiDashboard/InsertLottieJson">
                      <InsertLottieFile />
                    </Route>
                    <Route path="/BiDashboard/UpdateLottieJson/:id">
                      <UpdateLottieFile />
                    </Route>
                    <Route path="/BiDashboard/mentorkart">
                      <Mentorkart />
                    </Route>
                    <Route path="/BiDashboard/featuredApply">
                      <FeaturedApplyresume />
                    </Route>
                    <Route path="/BiDashboard/PartnerCompanies">
                      <PartnerCompanies />
                    </Route>
                    <Route path="/BiDashboard/PaymentSuccToday">
                      <PaymentSuccToday />
                    </Route>
                    <Route path="/BiDashboard/RecruiterSignupDet">
                      <RecruiterSignupDet />
                    </Route>
                    <Route path="/BiDashboard/RecruiterSignupMonthlty">
                      <RecruiterSignupMonthlty />
                    </Route>
                    <Route path="/BiDashboard/PaymentSuccMonthly">
                      <PaymentSuccMonthly />
                    </Route>
                    <Route path="/BiDashboard/PaymentFailToday">
                      <PaymentFailToday />
                    </Route>
                    <Route path="/BiDashboard/OnlineJobs">
                      <OnlineJobs />
                    </Route>
                    <Route path="/BiDashboard/OnlineJobsMonthly">
                      <OnlineJobMonthly />
                    </Route>
                    <Route path="/BiDashboard/EasyApplyToday">
                      <EasyApplyToday />
                    </Route>
                    <Route path="/BiDashboard/EasyApplyDataPointsLastMonth">
                      <EasyApplyDataPointsToday />
                    </Route>
                    <Route path="/BiDashboard/EasyApplyJobs/:id">
                      <EasyApplyJobDetail />
                    </Route>
                    <Route path="/BiDashboard/EasyApplyJobsDetail">
                      <EasyApplydetail />
                    </Route>
                    <Route path="/BiDashboard/EasyApplyThroghId">
                      <EasyApplyThroghId />
                    </Route>
                    <Route path="/BiDashboard/RediectionPartner">
                      <RedictionPartners />
                    </Route>
                    <Route path="/BiDashboard/RedirectionDetail/:detailcompany/:detailDate">
                      <RedirectionDetail />
                    </Route>
                    <Route path="/BiDashboard/RedirectionPartnerDate">
                      <RedirectionPartnerDate />
                    </Route>
                    <Route path="/BiDashboard/EasyApplyMonthly">
                      <EasyApplyMonthly />
                    </Route>
                    <Route path="/BiDashboard/SignupLastMonth">
                      <SignupLastMonth />
                    </Route>
                    <Route path="/BiDashboard/SignupLastMonthDetail/">
                      <SignupLastMonthDetail />
                    </Route>
                    <Route path="/BiDashboard/ResumeLastMonth">
                      <ResumeUploadLastMonth />
                    </Route>
                    <Route path="/BiDashboard/ResumeUploadsLastMonthDetail">
                      <ResumeUploadsLastMonthDetail />
                    </Route>
                    <Route path="/BiDashboard/CourseDataPoints">
                      <CourseDataPoints />
                    </Route>
                    <Route path="/BiDashboard/CourseDetail">
                      <CoursedataDetails />
                    </Route>
                    <Route path="/BiDashboard/CourseById">
                      <CourseById />
                    </Route>
                    <Route path="/BiDashboard/RecruiterLoginDataPoints">
                      <RecruiterLoginDataPoints />
                    </Route>
                    <Route path="/BiDashboard/RecruiterLoginDetail">
                      <RecruiterLoginDetail />
                    </Route>
                    <Route path="/BiDashboard/Login">
                      <LoginDataPoints />
                    </Route>
                    <Route path="/BiDashboard/Logindetail">
                      <Logindatadetail />
                    </Route>
                    <Route path="/BiDashboard/ArticleData">
                      <ArticleDataPoints />
                    </Route>
                    <Route path="/BiDashboard/ArticleDetail">
                      <ArticleDetail />
                    </Route>
                    <Route path="/BiDashboard/ImpressionWidget">
                      <ImpressionWidget />
                    </Route>
                    <Route path="/BiDashboard/ImpressionDetail">
                      <ImpressionDetail />
                    </Route>
                    <Route path="/BiDashboard/landingpage">
                      <LandingPage />
                    </Route>
                    <Route path="/BiDashboard/landingpageDetail">
                      <LandingPagedetails />
                    </Route>
                    <Route path="/BiDashboard/AdvertiseNowDetails">
                      <AdvertiseNowDetails />
                    </Route>
                    <Route path="/BiDashboard/ContactUsDetails">
                      <ContactUsDetails />
                    </Route>
                    <Route path="/BiDashboard/PaymentFailMonthly">
                      <PaymentFailMonthly />
                    </Route>
                    <Route path="/BiDashboard/orders">
                      <Orders />
                    </Route>
                    <Route path="/BiDashboard/PostAJobs">
                      <PostAJobs />
                    </Route>
                    <Route path="/BiDashboard/InterstitialAds">
                      <InterstitialAds />
                    </Route>
                    <Route path="/BiDashboard/events">
                      <Events />
                    </Route>
                    <Route path="/BiDashboard/addevents">
                      <AddEvents />
                    </Route>
                    <Route path="/BiDashboard/eventdetail/:id">
                      <EventDetail />
                    </Route>
                    <Route path="/BiDashboard/updateevent/:id">
                      <UpdateEvent />
                    </Route>
                    <Route path="/BiDashboard/eventsregisterlist">
                      <EventRegisterList />
                    </Route>
                    <Route path="/BiDashboard/EventRegisterDetail/:id">
                      <EventRegisterDetail />
                    </Route>
                    <Route path="/BiDashboard/courses">
                      <Cources />
                    </Route>
                    <Route path="/BiDashboard/coursedetail/:id">
                      <CourseDetail />
                    </Route>
                    <Route path="/BiDashboard/addcourses">
                      <AddCourses />
                    </Route>
                    <Route path="/BiDashboard/updatecourses/:id">
                      <UpdateCourse />
                    </Route>
                    <Route path="/BiDashboard/hrprofessionnal">
                      <HrProfessional />
                    </Route>
                    <Route path="/BiDashboard/HrProfessionalDetails/:id">
                      <HrProfessionalDetails />
                    </Route>
                    <Route path="/BiDashboard/addHrProfessional">
                      <AddHrProfessional />
                    </Route>
                    <Route path="/BiDashboard/updateHr/:id">
                      <UpdateHr />
                    </Route>

                    <Route path="/BiDashboard/FeaturedCompanyList">
                      <FeaturedCompanyList />
                    </Route>
                    <Route path="/BiDashboard/JobsList">
                      <JobsList />
                    </Route>
                    <Route path="/BiDashboard/featuredcompanies">
                      <FeaturedCompanies />
                    </Route>
                    <Route path="/BiDashboard/featuredCompaniesDetails/:id">
                      <FeaturedCompaniesDetails />
                    </Route>
                    <Route path="/BiDashboard/addfeaturedcompanies">
                      <AddFeaturedCompanies />
                    </Route>
                    <Route path="/BiDashboard/updatefeaturedcompanies/:id">
                      <UpdateFeaturedCompanies />
                    </Route>
                    <Route path="/BiDashboard/admindatapoints">
                      <AdminDataPoints />
                    </Route>
                    <Route path="/BiDashboard/FeaturedJobdetail">
                      <FeaturedCompanyDetail />
                    </Route>
                    <Route path="/BiDashboard/featuredemployersdetail/:id">
                      <FeaturedEmployerDetail />
                    </Route>
                    <Route path="/BiDashboard/articles">
                      <Articles />
                    </Route>
                    <Route path="/BiDashboard/updateJson">
                      <UpdatePageJson />
                    </Route>
                    <Route path="/BiDashboard/newArticle">
                      <NewArticle />
                    </Route>
                    <Route path="/BiDashboard/viewArticle/:id">
                      <ViewArticle />
                    </Route>
                    <Route path="/BiDashboard/editArticle/:id">
                      <EditArticle />
                    </Route>

                    <Route path="/BiDashboard/EditEvent/:id">
                      <EditEvent />
                    </Route>

                    <Route path="/BiDashboard/EditCourse/:id">
                      <EditCourse />
                    </Route>

                    <Route path="/BiDashboard/postAJob">
                      <PostAJob />
                    </Route>
                    <Route path="/BiDashboard/recruiterSignUp">
                      <RecruiterSignUp />
                    </Route>
                    <Route path="/BiDashboard/TOIWidget">
                      <TOIWidget />
                    </Route>
                    <Route path="/BiDashboard/UpdateTOIWidget/:id">
                      <UpdateTOIWidget />
                    </Route>
                    <Route path="/BiDashboard/TOIWidgetList">
                      <TOIWidgetList />
                    </Route>
                    <Route path="/BiDashboard/InterstitialAd">
                      <InterstitialAd />
                    </Route>
                    <Route path="/BiDashboard/Dashboard">
                      <Dashboard />
                    </Route>
                    <Route path="/BiDashboard/DataPoints">
                      <DataPoints />
                    </Route>
                    <Route path="/BiDashboard/GetListofUserspostingJob">
                      <GetListofUserspostingJob />
                    </Route>
                    <Route path="/BiDashboard/contact-us-list">
                      <GetListofUserspostingJob />
                    </Route>
                    <Route path="/BiDashboard/forher-post-by-id">
                      <AddForherById />
                    </Route>
                    <Route path="/BiDashboard/forher-post-list">
                      <ForherPostList />
                    </Route>
                    <Route path="/BiDashboard/forher-Offers">
                      <ForherOffers />
                    </Route>
                    <Route path="/BiDashboard/youtube-video">
                      <YoutubeVideo />
                    </Route>
                    <Route path="/BiDashboard/addOffers">
                      <AddOffers />
                    </Route>
                    <Route path="/BiDashboard/addYoutubeVideo">
                      <AddYoutubeVideo />
                    </Route>
                    <Route path="/BiDashboard/editOffers/:id">
                      <EditOffer />
                    </Route>
                    <Route path="/BiDashboard/editYoutubeVideo/:id">
                      <EditYoutubeVideo />
                    </Route>
                    <Route path="/BiDashboard/offerdetail/:id">
                      <EventDetail />
                    </Route>
                    <Route path="/BiDashboard/podcasts">
                      <Podcasts />
                    </Route>
                    <Route path="/BiDashboard/newPodcast">
                      <AddPodcast />
                    </Route>
                    <Route path="/BiDashboard/editPodcast/:id">
                      <UpdatePodcast />
                    </Route>
                    <Route path="/BiDashboard/podcast-videos">
                      <PodcastVideos />
                    </Route>
                    <Route path="/BiDashboard/newPodcastVideo">
                      <AddPodcastVideo />
                    </Route>
                    <Route path="/BiDashboard/greatManagerInstitute">
                      <GreatManagerInstitute />
                    </Route>
                    <Route path="/BiDashboard/newGreatManagerInstitute">
                      <AddGreatManagerInstitute />
                    </Route>
                    <Route path="/BiDashboard/editGreatManagerInstitute/:id">
                      <UpdateGreatManagerInstitute />
                    </Route>
                    <Route path="/BiDashboard/leadershipFactory">
                      <LeadershipFactoryList />
                    </Route>
                    <Route path="/BiDashboard/loginData">
                      <LoginData />
                    </Route>
                    <Route path="/BiDashboard/newLeadershipFactory">
                      <LeadershipFactoryInsert />
                    </Route>
                    <Route path="/BiDashboard/editLeadershipFactory/:id">
                      <EditLeadershipFactory />
                    </Route>
                    <Route path="/BiDashboard/nominateYourManager">
                      <NominateYourManager />
                    </Route>
                    <Route path="/BiDashboard/downloadArticles">
                      <ArticlesDownload />
                    </Route>
                    <Route path="/BiDashboard/certificationOfWinners">
                      <CertificationOfWinners />
                    </Route>
                    <Route path="/BiDashboard/addCertificationOfWinners">
                      <AddCertificationOfWinners />
                    </Route>
                    <Route path="/BiDashboard/editCertificationOfWinners/:id">
                      <EditCertificationOfWinners />
                    </Route>
                    <Route path="/BiDashboard/leadersOfChange">
                      <LeadersOfChange />
                    </Route>
                    <Route path="/BiDashboard/addLeadersOfChange">
                      <AddLeadersOfChange />
                    </Route>
                    <Route path="/BiDashboard/editLeadersOfChange/:id">
                      <EditLeadersOfChange />
                    </Route>

                    <Route path="/BiDashboard/articleManagers">
                      <ArticleManager />
                    </Route>
                    <Route path="/BiDashboard/AddArticleManager">
                      <AddArticleManager />
                    </Route>
                    <Route path="/BiDashboard/editArticleManager/:id">
                      <UpdateManager />
                    </Route>
                  </Switch >
                </div >
              </>
            ) : accessLevel === "TIMES" ? (
              <>
                <Header userType={"TIMES"} />
                <div className={classes.container}>
                  <Sidebar userType={"TIMES"} />
                  <Switch>
                    <Route path="/BiDashboard/FeaturedCompanyList">
                      <FeaturedCompanyList accessLevel={accessLevel} />
                    </Route>
                    <Route path="/BiDashboard/articles">
                      <Articles accessLevel={accessLevel} />
                    </Route>
                    <Route path="/BiDashboard/viewArticle/:id">
                      <ViewArticle />
                    </Route>
                    <Route path="/BiDashboard/courses">
                      <Cources accessLevel={accessLevel} />
                    </Route>
                    <Route path="/BiDashboard/webstorylist">
                      <WebstoryList accessLevel={accessLevel} />
                    </Route>
                    <Route path="/BiDashboard/Webstorydetail/:id">
                      <Webstorydetail accessLevel={accessLevel} />
                    </Route>
                    <Route path="/BiDashboard/Webstoryupdate/:id">
                      <Webstoryupdate accessLevel={accessLevel} />
                    </Route>
                    <Route path="/BiDashboard/updatewebstorydetail/:id">
                      <WebstoryDeatilUpdate accessLevel={accessLevel} />
                    </Route>
                    <Route path="/BiDashboard/AddWebstory">
                      <AddWebstory accessLevel={accessLevel} />
                    </Route>
                    <Route path="/BiDashboard/AddWebstorySummary/:id">
                      <AddWebstorySummary accessLevel={accessLevel} />
                    </Route>
                    <Route path="/BiDashboard/corporates-care">
                      <Wecarelist />
                    </Route>
                    <Route path="/BiDashboard/coursedetail/:id">
                      <CourseDetail />
                    </Route>

                    <Route path="/BiDashboard/CompanyList">
                      <CompanyList />
                    </Route>

                    <Route path="/BiDashboard/JobList">
                      <JobList />
                    </Route>



                    <Route path="/BiDashboard/CompanyView/:id">
                      <CompanyView />
                    </Route>
                    <Route path="/BiDashboard/WeCareList">
                      <Wecarelist />
                    </Route>
                    <Route path="/BiDashboard/WeCareMopFormList">
                      <WeCareMopList />
                    </Route>
                    <Route path="/BiDashboard/WeCareMopFormDetail/:id">
                      <WeCareMopFormDetail />
                    </Route>
                    <Route path="/BiDashboard/AddWeCare">
                      <AddWecare />
                    </Route>
                    <Route path="/BiDashboard/NewAddCorporate">
                      <NewAdmin />
                    </Route>
                    <Route path="/BiDashboard/EditWecare:id">
                      <EditWecare />
                    </Route>{" "}
                    <Route path="/BiDashboard/ViewDetail/:id">
                      <ViewDetail />
                    </Route>
                    <Route path="/BiDashboard/MovementList">
                      <MovementList />
                    </Route>
                    <Route path="/BiDashboard/AddMovement">
                      <MovementAdd />
                    </Route>
                    <Route path="/BiDashboard/EditMovement/:id">
                      <MovementEdit />
                    </Route>
                    <Route path="/BiDashboard/Movementdetail/:id">
                      <MovementView />
                    </Route>
                    <Route path="/BiDashboard/AddGuestArticle">
                      <AddGuest />
                    </Route>
                    <Route path="/BiDashboard/GuestArticleList">
                      <GuestList />
                    </Route>
                    <Route path="/BiDashboard/EditGuestArticle/:id">
                      <EditGuestArticle />
                    </Route>
                    <Route path="/BiDashboard/dynamicwidget">
                      <WidgetList accessLevel={accessLevel} />
                    </Route>
                    <Route path="/BiDashboard/PartnerCompanies">
                      <PartnerCompanies />
                    </Route>
                    <Route path="/BiDashboard/PollsList">
                      <PollsList />
                    </Route>
                    <Route path="/BiDashboard/CreatePoll">
                      <CreatePoll />
                    </Route>
                    <Route path="/BiDashboard/UpdatePolls/:id">
                      <UpdateApoll />
                    </Route>
                    <Route path="/BiDashboard/mentorkart">
                      <Mentorkart />
                    </Route>
                    <Route path="/BiDashboard/featuredApply">
                      <FeaturedApplyresume />
                    </Route>
                    <Route path="/BiDashboard/DataPoints">
                      <DataPoints />
                    </Route>
                    <Route path="/BiDashboard/admindatapoints">
                      <AdminDataPoints />
                    </Route>
                    <Route path="/BiDashboard/FeaturedJobdetail">
                      <FeaturedCompanyDetail />
                    </Route>
                    <Route path="/BiDashboard/featuredemployersdetail/:id">
                      <FeaturedEmployerDetail />
                    </Route>
                    <Route path="/BiDashboard/EasyApplyDataPointsLastMonth">
                      <EasyApplyDataPointsToday />
                    </Route>
                    <Route path="/BiDashboard/EasyApplyJobs/:id">
                      <EasyApplyJobDetail />
                    </Route>
                    <Route path="/BiDashboard/EasyApplyJobsDetail">
                      <EasyApplydetail />
                    </Route>
                    <Route path="/BiDashboard/EasyApplyThroghId">
                      <EasyApplyThroghId />
                    </Route>
                    <Route path="/BiDashboard/RediectionPartner">
                      <RedictionPartners />
                    </Route>
                    <Route path="/BiDashboard/RedirectionDetail/:detailcompany/:detailDate">
                      <RedirectionDetail />
                    </Route>
                    <Route path="/BiDashboard/RedirectionPartnerDate">
                      <RedirectionPartnerDate />
                    </Route>
                    <Route path="/BiDashboard/EasyApplyMonthly">
                      <EasyApplyMonthly />
                    </Route>
                    <Route path="/BiDashboard/SignupLastMonth">
                      <SignupLastMonth />
                    </Route>
                    <Route path="/BiDashboard/SignupLastMonthDetail/">
                      <SignupLastMonthDetail />
                    </Route>
                    <Route path="/BiDashboard/ResumeLastMonth">
                      <ResumeUploadLastMonth />
                    </Route>
                    <Route path="/BiDashboard/ResumeUploadsLastMonthDetail">
                      <ResumeUploadsLastMonthDetail />
                    </Route>
                    <Route path="/BiDashboard/CourseDataPoints">
                      <CourseDataPoints />
                    </Route>
                    <Route path="/BiDashboard/CourseDetail">
                      <CoursedataDetails />
                    </Route>
                    <Route path="/BiDashboard/CourseById">
                      <CourseById />
                    </Route>
                    <Route path="/BiDashboard/RecruiterLoginDataPoints">
                      <RecruiterLoginDataPoints />
                    </Route>
                    <Route path="/BiDashboard/RecruiterLoginDetail">
                      <RecruiterLoginDetail />
                    </Route>
                    <Route path="/BiDashboard/Login">
                      <LoginDataPoints />
                    </Route>
                    <Route path="/BiDashboard/Logindetail">
                      <Logindatadetail />
                    </Route>
                    <Route path="/BiDashboard/ArticleData">
                      <ArticleDataPoints />
                    </Route>
                    <Route path="/BiDashboard/ArticleDetail">
                      <ArticleDetail />
                    </Route>
                    <Route path="/BiDashboard/ImpressionWidget">
                      <ImpressionWidget />
                    </Route>
                    <Route path="/BiDashboard/ImpressionDetail">
                      <ImpressionDetail />
                    </Route>
                    <Route path="/BiDashboard/PaymentSucc">
                      <PaymentSuccToday />
                    </Route>
                    <Route path="/BiDashboard/PaymentSuccMonthly">
                      <PaymentSuccMonthly />
                    </Route>
                    <Route path="/BiDashboard/RecruiterSignupDet">
                      <RecruiterSignupDet />
                    </Route>
                    <Route path="/BiDashboard/RecruiterSignupMonthlty">
                      <RecruiterSignupMonthlty />
                    </Route>
                    <Route path="/BiDashboard/OnlineJobs">
                      <OnlineJobs />
                    </Route>
                    <Route path="/BiDashboard/OnlineJobsMonthly">
                      <OnlineJobMonthly />
                    </Route>
                    <Route path="/BiDashboard/EasyApplyToday">
                      <EasyApplyToday />
                    </Route>
                    <Route path="/BiDashboard/EasyApplyJobDetail">
                      <EasyApplyJobDetail />
                    </Route>
                    <Route path="/BiDashboard/EasyApplyMonthly">
                      <EasyApplyMonthly />
                    </Route>
                    <Route path="/BiDashboard/AdvertiseNowDetails">
                      <AdvertiseNowDetails />
                    </Route>
                    {/* <Route path="/BiDashboard/ContactUsDetails">
                      <ContactUsDetails />
                    </Route> */}
                    <Route path="/BiDashboard/PaymentSuccMonthly">
                      <PaymentSuccMonthly />
                    </Route>
                    <Route path="/BiDashboard/PaymentFailToday">
                      <PaymentFailToday />
                    </Route>
                    <Route path="/BiDashboard/PaymentFailMonthly">
                      <PaymentFailMonthly />
                    </Route>
                    <Route path="/BiDashboard/orders">
                      <Orders />
                    </Route>
                    <Route path="/BiDashboard/PostAJobs">
                      <PostAJobs />
                    </Route>
                    <Route path="/BiDashboard/TOIWidget">
                      <TOIWidget />
                    </Route>
                    <Route path="/BiDashboard/UpdateTOIWidget/:id">
                      <UpdateTOIWidget />
                    </Route>
                    <Route path="/BiDashboard/TOIWidgetList">
                      <TOIWidgetList />
                    </Route>
                    <Route path="/BiDashboard/Dashboard">
                      <Dashboard />
                    </Route>
                    <Route path="/BiDashboard/ContactUsDetails">
                      <ContactUsDetails />
                    </Route>
                    <Route path="/BiDashboard/eventList">
                      <EventList />
                    </Route>
                    <Route path="/BiDashboard/events">
                      <Events accessLevel={accessLevel} />
                    </Route>
                    <Route path="/BiDashboard/eventdetail/:id">
                      <EventDetail />
                    </Route>
                    <Route path="/BiDashboard/ShareHrprofilelist">
                      <HrshareFormlist />
                    </Route>
                    <Route path="/BiDashboard/hrprofessionnal">
                      <HrProfessional accessLevel={accessLevel} />
                    </Route>
                    <Route path="/BiDashboard/HrProfessionalDetails/:id">
                      <HrProfessionalDetails />
                    </Route>
                    <Route path="/BiDashboard/write-for-us-list">
                      <WriteForUsList />
                    </Route>
                    <Route path="/BiDashboard/courses-list">
                      <CourseList />
                    </Route>
                    <Route path="/BiDashboard/MopJourney">
                      <MopJourney />
                    </Route>
                    <Route path="/BiDashboard/editArticle/:id">
                      <EditArticle />
                    </Route>
                    <Route path="/BiDashboard/loginData">
                      <LoginData />
                    </Route>
                  </Switch>
                </div >
              </>
            ) : accessLevel === "ADSDB" ? (
              <>
                <Header userType={"ADSDB"} />
                <div className={classes.container}>
                  <div className={classes.w_100}>
                    <BiHeaderNew accessLevel="ADSDB" />
                    <Switch>
                      <Route path="/printAd/jobDashboard/:id">
                        <GridDashboard />
                      </Route>
                      <Route path="/printAd/jobDashboard:QC/:id">
                        <GridDashboard />
                      </Route>
                      <Route path="/NonAscentJobs/jobDashboard/:id">
                        <GridDashboard />
                      </Route>
                      <Route path="/NonAscentJobs/jobDashboard:QC/:id">
                        <GridDashboard />
                      </Route>
                      <Route path="/printAd/jobDashboard/:id">
                        <GridDashboard />
                      </Route>
                      <Route path="/printAd/jobDashboard:QC/:id">
                        <GridDashboard />
                      </Route>
                      <Route path="/postAJob/jobDashboard/:id">
                        <GridDashboard />
                      </Route>
                      <Route path="/postAJob/jobDashboard:QC/:id">
                        <GridDashboard />
                      </Route>
                      <Route path="/printAd/fetchAds">
                        <FetchAds />
                      </Route>

                      <Route path="/printAd/inbox">
                        <FetchAdsInbox />
                      </Route>
                      <Route path="/printAd/QCList">
                        <QCList />
                      </Route>
                      <Route path="/printAd/junkList">
                        <JunkList />
                      </Route>
                      <Route path="/printAd/Draft">
                        <Draft />
                      </Route>
                      <Route path="/printAd/liveJobs">
                        <PrintAdsLiveJobs />
                      </Route>
                      <Route path="/NonAscentJobs/adsList">
                        <NonAscentJobsAdsList />
                      </Route>
                      <Route path="/NonAscentJobs/liveJobs">
                        <NonAscentJobsLiveJobs />
                      </Route>
                      <Route path="/postAJob/adsList">
                        <PostAJobsAdsList />
                      </Route>
                      <Route path="/postAJob/liveJobs">
                        <PostaJobsLiveJobs />
                      </Route>
                      <Route path="/RecruiterDetails">
                        <RecruiterDetailsNew />
                      </Route>
                      <Route path="/BiDashboard/GoogleSignIn">
                        <GoogleSignIn />
                      </Route>
                      <Route path="/BiDashboard/RecruiterDetails">
                        <RecruiterDetails />
                      </Route>
                      <Route path="/BiDashboard/CreateCompany">
                        <CreateCompany />
                      </Route>
                      <Route path="/BiDashboard/AdsQc">
                        <AdsQc />
                      </Route>
                      <Route path="/BiDashboard/Reports">
                        <Reports />
                      </Route>
                      <Route path="/BiDashboard/JobAds/:id">
                        <JobAds />
                      </Route>
                      <Route path="/BiDashboard/Qcform/:id">
                        <Qcform />
                      </Route>
                      <Route path="/BiDashboard/Editform/:id">
                        <Editform />
                      </Route>
                      <Route path="/BiDashboard/JobPostingDetail/:id/:companyID">
                        <JobPostingDetail />
                      </Route>
                      <Route path="/BiDashboard/RecruiterDetails">
                        <RecruiterDetails />
                      </Route>
                      <Route path="/BiDashboard/Recruiters/:id">
                        <Recruiters />
                      </Route>
                      <Route path="/BiDashboard/DashJobList">
                        <DashJobList />
                      </Route>

                      <Route path="/BiDashboard/GoogleSignIn">
                        <GoogleSignIn />
                      </Route>
                      <Route path="/BiDashboard/OrderDetails/:id">
                        <OrderDetails />
                      </Route>
                      <Route path="/BiDashboard/NewJobAds">
                        <NewJobAds />
                      </Route>
                      <Route path="/BiDashboard/AdminAdsEntry/:companyId">
                        <AdminAdsEntry />
                      </Route>
                      <Route path="/BiDashboard//JobDetails/:id">
                        <JobDetails />
                      </Route>
                      <Route path="/BiDashboard/Dashboard"></Route>
                    </Switch>
                  </div>
                </div>
              </>
            ) : accessLevel === "ADSDBSub" ? (
              <>
                <Header userType={"ADSDBSub"} />
                <div className={classes.container}>
                  <div className={classes.w_100}>
                    <BiHeaderNew accessLevel="ADSDBSub" />
                    <Switch>
                      <Route path="/printAd/jobDashboard/:id">
                        <GridDashboard />
                      </Route>

                      <Route path="/printAd/fetchAds">
                        <FetchAds />
                      </Route>
                      <Route path="/printAd/inbox">
                        <FetchAdsInbox />
                      </Route>
                      <Route path="/printAd/QCList">
                        <QCList />
                      </Route>
                      <Route path="/printAd/Draft">
                        <Draft />
                      </Route>
                      <Route path="/RecruiterDetails">
                        <RecruiterDetailsNew />
                      </Route>
                      <Route path="/BiDashboard/Dashboard"></Route>
                      <Route path="/BiDashboard/DashJobList">
                        <DashJobList />
                      </Route>
                      <Route path="/BiDashboard/Qcform/:id">
                        <Qcform />
                      </Route>
                      <Route path="/BiDashboard/JobPostingDetail/:id/:companyID">
                        <JobPostingDetail />
                      </Route>
                      <Route path="/BiDashboard/JobAds/:id">
                        <JobAds />
                      </Route>

                      <Route path="/BiDashboard/GoogleSignIn">
                        <GoogleSignIn />
                      </Route>
                    </Switch>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            {/* accessLevel === "ADSDB" ?
                  <>
                   
                    <Switch>
                      <Route path="/BiDashboard/RecruiterDetails">
                        <RecruiterDetails />
                      </Route>
                      <Route path="/BiDashboard/CreateCompany">

                        <CreateCompany />
                      </Route>
                      <Route path="/BiDashboard/AdsQc">

                        <AdsQc />
                      </Route>
                      <Route path="/BiDashboard/Reports">

                        <Reports />
                      </Route>
                      <Route path="/BiDashboard/JobAds/:id" >
                        <JobAds />
                      </Route>
                      <Route path="/BiDashboard/Qcform/:id" >
                        <Qcform />
                      </Route>
                      <Route path="/BiDashboard/Editform/:id" >
                        <Editform />
                      </Route>
                      <Route path="/BiDashboard/JobPostingDetail/:id/:companyID">

                        <JobPostingDetail />
                      </Route>
                      <Route path="/BiDashboard/RecruiterDetails">

                        <RecruiterDetails />
                      </Route>
                      <Route path="/BiDashboard/Recruiters/:id">
                        <Recruiters />
                      </Route>
                      <Route path="/BiDashboard/DashJobList" >

                        <DashJobList />
                      </Route>


                      <Route path="/BiDashboard/GoogleSignIn" >
                        <GoogleSignIn />
                      </Route>
                      <Route path="/BiDashboard/OrderDetails/:id">
                        <OrderDetails />
                      </Route>
                      <Route path="/BiDashboard/NewJobAds">
                        <NewJobAds />
                      </Route>
                      <Route path="/BiDashboard/AdminAdsEntry/:companyId">
                        <AdminAdsEntry />
                      </Route>
                      <Route path="/BiDashboard//JobDetails/:id">
                        <JobDetails />
                      </Route>
                      <Route path="/BiDashboard/Dashboard">
                        <Inbox />
          </Route>
        </Switch>
      </>
      : accessLevel === "ADSDBSub" ?
      <>
        <Switch>
          <Route path="/BiDashboard/Dashboard">
            <Inbox />
          </Route>
          <Route path="/BiDashboard/DashJobList" >
            <DashJobList />
          </Route>
          <Route path="/BiDashboard/Qcform/:id" >
            <Qcform />
          </Route>
          <Route path="/BiDashboard/JobPostingDetail/:id/:companyID">
            <JobPostingDetail />
          </Route>
          <Route path="/BiDashboard/JobAds/:id" >
            <JobAds />
          </Route>
        </Switch>
      </>: <></> */}
          </div >
        </div >
      </div >

      <div className={classes.mob_view}>
        <div>
          <BiError className={classes.icon_Bi} />
        </div>
        <h1 className={classes.mobView_text}>please open on Web</h1>
      </div>
    </div >
  );
};
const Redirection = (props) => {
  const { pathname } = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (sessionStorage.getItem("googleBiEmail")) {
      props.biAuthCheck(sessionStorage.getItem("googleBiEmail"));
    } else {
      history.push("/BiDashboard/SignInWithGoogle");
    }
  }, [pathname]);

  return <></>;
};
export default MainRoute;
