import React, { useEffect, useState } from "react";

import classes from "../Webinarvideos/InsertVideo.module.css";

//port { useHistory, useParams, Link } from "react-router-dom";

import Loader from "react-loader-spinner";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";
import Modal from "../Components/Modal";
import EventInputs from "../Components/Events/EventInputs";
import BackButton from "../Components/BackButton";
import { Link, useHistory, useParams } from "react-router-dom";
import LoadingSpinner from "../Components/LoadingSpinner";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";

const AddSkill = () => {
  const { id } = useParams();
  console.log(id, "uhibgdui");
  const [customContentList, setCustomContentList] = useState([]);
  const [Question, setQuestion] = useState("");
  const [loading, setLoading] = useState(false);
  const [Answer, setAnswer] = useState("");
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [SeoURL, setSeoUrl] = useState("")
  let history = useHistory()



  const UpdateApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({

      skillName: Question,
      keywordUUID: id,

    });


    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/SkillInsert",
      //process.env.REACT_APP_API_URL + "/adminapi/UpdateWealthFAQ",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    CustomJobPageListApi();
  }, []);
  const CustomJobPageListApi = () => {
    setLoading(true);

    // Prepare headers
    const myHeaders = new Headers({
      Authorization: "Token 77927b69bb144b065ca11bf2a9d452819cd852db",
      "Content-Type": "application/json",
    });

    // Request body
    const raw = JSON.stringify({
      //Page: currentPage,
      keywordUUID: id,
    });

    // Request options
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    // Fetch data
    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/keywordsById",
      requestOptions
    )
      .then((response) => {
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        return response.json();
      })
      .then((result) => {

        console.log(result, "adfhusdghigspif");
        if (result.status_code === 200) {
          setCustomContentList(result.skills);
          console.log(result.Keywords, "Loaded Keywords");
        } else {
          console.error("Unexpected response status:", result);
        }
      })
      .catch((error) => {
        console.error("Error during API call:", error);
      })
      .finally(() => setLoading(false));
  };

  const DeleteApi = (id) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      skillUUID: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/skilldeleteById",
      //process.env.REACT_APP_API_URL + "/adminapi/keyworddeleteById",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setModalShow(true);
          CustomJobPageListApi();
          setLoading(false)
        }
      })
      .catch((error) => console.log("error", error));

  };

  const pageInfo = {
    currentPageName: "Add skill",
    previousPageName: "Key word List",
    previousPage: "/BiDashboard/KeyWordList",
  };

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal heading={"Data updated successfully"} onClick={() => history.push("/BiDashboard/KeyWordList")} />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Are Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.TimsbiBreadcrumb}>
            {" "}
            <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
          </div>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Add Skill</div>
            <Link to="/BiDashboard/KeyWordList" className={classes.BackButton}>
              <BackButton />
            </Link>
          </div>
          <EventInputs
            input={"SingleLineInput"}
            label={"Skill"}
            placeholder={"Enter Skill"}
            onChange={(e) => setQuestion(e.target.value)}
            value={Question}
            required
          />
          <button className={classes.Upload_button} onClick={() => UpdateApi()}>
            {loading ? (
              <Loader
                type="TailSpin"
                color="white"
                height={20}
                width={50}
                className="text-center"
              />
            ) : (
              "Add"
            )}
          </button>

          <div className={classes.table_container}>
            <table className={classes.orders_table}>
              {/* Table header */}
              <thead>
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>S.No.</th>
                  <th className={classes.table_heading}>keyword Name</th>

                  <th className={classes.table_heading}>Seo Name</th>

                </tr>
              </thead>

              {/* Table body with data rows */}
              <tbody>
                {customContentList ? (
                  customContentList.map((item, index) => (
                    <tr key={item.CustomJobPageUUID} className={classes.table_row}>
                      <td className={classes.table_td}>{index + 1}</td>
                      <td className={classes.table_td}>{item.skillName}</td>
                      <td className={classes.table_td}>{item.skillSeoName}</td>

                      <td className={classes.table_td}>
                        <div
                          className={classes.customLinkDelete}
                          onClick={() => DeleteApi(item.skillUUID)}
                        >
                          {loading ? (
                            <Loader
                              type="TailSpin"
                              color="white"
                              height={20}
                              width={50}
                              className="text-center"
                            />
                          ) : (
                            <MdDeleteForever size={20} />
                          )}
                        </div>
                      </td>

                      {/* <td className={classes.table_td}>{formatDate(item.CreatedAt)}</td> */}


                    </tr>
                  ))
                ) : (
                  <tr className={classes.No_data_row}>
                    <td colSpan="8" className={classes.No_data}>
                      No data found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>




      </div>

    </div>

  );
};

export default AddSkill;
