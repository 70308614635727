import React, { useEffect, useState } from "react";
import classes from "./AddCustomJobContent.module.css";
import { Link, useHistory } from "react-router-dom";
import BackButton from "../Components/BackButton";
import Modal from "../Components/Modal";
import EventInputs from "../Components/Events/EventInputs";
import { useParams } from "react-router-dom";

const CompanyView = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");
  const [industry, setIndustry] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [panCardImageUrl, setPanCardImageUrl] = useState(null);
  const [location, setLocation] = useState("");
  const [positionTitle, setPositionTitle] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [seoUrl, setSeoUrl] = useState("");
  const [check, setCheck] = useState(0);

  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const { id } = useParams();

  const generateSeoUrl = (title, company, loc) => {
    const formattedTitle = title.trim().toLowerCase().replace(/[^a-z0-9]+/g, "-");
    const formattedCompany = company.trim().toLowerCase().replace(/[^a-z0-9]+/g, "-");
    const formattedLocation = loc.trim().toLowerCase().replace(/[^a-z0-9]+/g, "-");
    const finalCompany = formattedCompany.endsWith("-") ? formattedCompany.slice(0, -1) : formattedCompany;

    return `new-jobs/${formattedTitle}/${finalCompany}/${formattedLocation}`;
  };

  useEffect(() => {
    if (positionTitle && companyName && location) {
      setSeoUrl(generateSeoUrl(positionTitle, companyName, location));
    }
  }, [positionTitle, companyName, location]);

  useEffect(() => {
    getDetailApi();
  }, []);

  const getDetailApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      recruiterUUID: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://pa6wvshfb4.execute-api.ap-southeast-1.amazonaws.com/staging/adminapi/RecruiterDetailbyId",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        const data = result.data;
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setEmail(data.email);
        setMobile(data.mobile);
        setCompanyName(data.companyName);
        setWebsite(data.website);
        setIndustry(data.industry);
        setCompanyDescription(data.companyDescription);
        setLocation(data.location);
        setPositionTitle(data.companyName); // Assuming positionTitle matches companyName
        setContactEmail(data.companyemail);
        setPostalCode(data.companyAddress);
        setCheck(data.isApproved === "1");
        setPanCardImageUrl(data.panCardImageUrl);
      })
      .catch((error) => console.log("Error:", error));
  };

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal heading="Data updated successfully" onClick={() => history.push("/BiDashboard/JobList")} />
      )}
      {errorShow && <Modal heading="Required Fields Missing" onClick={() => setErrorShow(false)} />}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Job Update</div>
            <Link to="/BiDashboard/CompanyList" className={classes.BackButton}>
              <BackButton />
            </Link>
          </div>
          <form className={classes.form_input}>
            <EventInputs
              input="SingleLineInput"
              label="First Name"
              placeholder="Enter First Name..."
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName || ""}
            />
            <EventInputs
              input="SingleLineInput"
              label="Last Name"
              placeholder="Enter Last Name..."
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
            />
            <EventInputs
              input="SingleLineInput"
              label="Company Name"
              placeholder="Enter Position Title..."
              value={positionTitle}
              readOnly
            />
            <EventInputs
              input="SingleLineInput"
              label="Industry"
              placeholder="Enter Industry..."
              onChange={(e) => setIndustry(e.target.value)}
              value={industry}
            />
            <EventInputs
              input="MultiLineInput"
              label="Company Description"
              placeholder="Company Description..."
              onChange={(e) => setCompanyDescription(e.target.value)}
              value={companyDescription}
            />
            <EventInputs
              input="SingleLineInput"
              label="Contact Email"
              placeholder="Enter Contact Email..."
              onChange={(e) => setContactEmail(e.target.value)}
              value={contactEmail}
            />
            <EventInputs
              input="SingleLineInput"
              label="Job Website"
              placeholder="Website..."
              onChange={(e) => setWebsite(e.target.value)}
              value={website}
            />
            <EventInputs
              input="SingleLineInput"
              label="Email"
              placeholder="Enter Email..."
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <EventInputs
              input="SingleLineInput"
              label="Mobile"
              placeholder="Enter Mobile Number..."
              onChange={(e) => setMobile(e.target.value)}
              value={mobile}
            />
            <EventInputs
              input="SingleLineInput"
              label="Company Name"
              placeholder="Enter Company Name..."
              onChange={(e) => setCompanyName(e.target.value)}
              value={companyName}
            />

            {/* PAN Card Image Section */}
            {panCardImageUrl && (
              <div className={classes.pan_card_section}>
                <label className={classes.pan_card_label}>PAN Card Image:</label>
                <div className={classes.pan_card_container}>
                  <img
                    src={panCardImageUrl}
                    alt="PAN Card"
                    className={classes.pan_card_image}
                  />
                  <a
                    href={panCardImageUrl}
                    download="PanCardImage.jpg"
                    className={classes.download_button}
                  >
                    Download
                  </a>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default CompanyView;
