import React from "react";
import classes from "./CandidateModal.module.css";
import { FaCopy } from "react-icons/fa";

const CandidateModal = (props) => {
  if (!props.isModalOpen) {
    return null;
  }

  const copyToClipboard = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert("URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const { iddata } = props;
  const referrelArray = iddata?.AdditionalDetails?.referrel ? JSON.parse(iddata?.AdditionalDetails?.referrel) : [];

  return (
    <>
      <div className={classes.overlay} onClick={props.onClose}></div>
      <div className={classes.modal}>
        {/* Modal Header */}
        <div className={classes.header}>
          <h2>Candidate Details</h2>
          <button className={classes.closeButton} onClick={props.onClose}>
            &times;
          </button>
        </div>

        {/* Modal Content */}
        <div className={classes.contentWrapper}>
          {/* Left Section: Image and Personal Details */}
          <h4>Basic Information</h4>
          <div className={classes.leftSection}>
            {iddata.profilepictureUrl && (
              <div className={classes.imageContainer}>
                <img
                  src={iddata.profilepictureUrl || "default-profile.jpg"}
                  alt={`${iddata.firstName} ${iddata.lastName}'s Profile`}
                  className={classes.image}
                />
              </div>
            )}

            <div className={classes.section}>
              <table className={classes.detailsTable}>
                <tbody>
                  <tr>
                    <th>Name</th>
                    <td>
                      {iddata.firstName + " " + iddata.lastName ||
                        "Not Provided"}
                    </td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{iddata.email || "Not Provided"}</td>
                  </tr>
                  <tr>
                    <th>Mobile</th>
                    <td>{iddata.mobile || "Not Provided"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={classes.section}>
              <table className={classes.detailsTable}>
                <tbody>
                  <tr>
                    <th>Category</th>
                    <td>{iddata.keywordName || "Not Provided"}</td>
                  </tr>
                  <tr>
                    <th>Skill Name</th>
                    <td>{iddata.skillName || "Not Provided"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* Right Section: Education, Work Experience, and Career */}
          <div className={classes.rightSection}>
            <div className={classes.section}>
              <hr />
              <h4>Job Specific Skills</h4>
              <table className={classes.detailsTable}>
                <tbody>
                  <tr>
                    <th>Preferred Job Title</th>
                    <td>{iddata.JobTitle || "Not Provided"}</td>
                  </tr>
                  <tr>
                    <th>Industry</th>
                    <td>{iddata.Industry || "Not Provided"}</td>
                  </tr>
                  <tr>
                    <th>Desired Salary</th>
                    <td>{iddata.Salary || "Not Provided"}</td>
                  </tr>
                  <tr>
                    <th>Employment Type</th>
                    <td>{iddata.EmploymentType || "Not Provided"}</td>
                  </tr>
                  <tr>
                    <th>Job Preferences</th>
                    <td>{iddata.JobPrefernces || "Not Provided"}</td>
                  </tr>
                  <tr>
                    <th>Professional Summary</th>
                    <td>{iddata.professionalSummary || "Not Provided"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={classes.section}>
              <hr/>
              <h4>Employment History</h4>
              {iddata.EmploymentDetails &&
              iddata.EmploymentDetails.length > 0 ? (
                iddata.EmploymentDetails.map((job, index) => (
                  <div key={index}>
                    <table className={classes.detailsTable}>
                      <tbody>
                        <tr>
                          <th>Job Title</th>
                          <td>{job.jobTitle || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>Organisation Name</th>
                          <td>{job.employerName || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>Joining Date</th>
                          <td>{job.fromDate || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>Worked Till Date</th>
                          <td>{job.toDate || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>Experience Type</th>
                          <td>{job.experienceType || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>Preferred Industry</th>
                          <td>{job.industry || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>Country</th>
                          <td>{job.country || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>State</th>
                          <td>{job.state || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>City</th>
                          <td>{job.city || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>Key Responsibility</th>
                          <td>{job.keyResponsibility || "Not Provided"}</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                  </div>
                ))
              ) : (
                <p>No Work Experience Available</p>
              )}
            </div>
            <div className={classes.section}>
              <h4>Education Details</h4>
              {iddata.EducationDetail && iddata.EducationDetail.length > 0 ? (
                iddata.EducationDetail.map((education, index) => (
                  <div key={index}>
                    <table className={classes.detailsTable}>
                      <tbody>
                        <tr>
                          <th>Degree</th>
                          <td>{education.Degree || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>Specialization</th>
                          <td>{education.Specialization || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>Institute</th>
                          <td>{education.Institute || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>Year of Passing</th>
                          <td>{education.YearOfPassing || "Not Provided"}</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                  </div>
                ))
              ) : (
                <p>No Education Details Available</p>
              )}
            </div>
            <div className={classes.section}>
              <h4>Certifications</h4>
              {iddata.Certifications && iddata.Certifications.length > 0 ? (
                iddata.Certifications.map((certification, index) => (
                  <div key={index}>
                    <table className={classes.detailsTable}>
                      <tbody>
                        <tr>
                          <th>Certification</th>
                          <td>
                            {certification.CertificationName || "Not Provided"}
                          </td>
                        </tr>
                        <tr>
                          <th>Issue Date</th>
                          <td>{certification.IssueDate || "Not Provided"}</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                  </div>
                ))
              ) : (
                <p>No Certifications Available</p>
              )}
            </div>

        
            <div className={classes.section}>
              <h4>Personal Details</h4>
              <div>
                    <table className={classes.detailsTable}>
                      <tbody>
                        <tr>
                          <th>Country</th>
                          <td>
                            {iddata?.PersonalDetails?.country || "Not Provided"}
                          </td>
                        </tr>
                        <tr>
                          <th>State</th>
                          <td>{iddata?.PersonalDetails?.state || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>City</th>
                          <td>{iddata?.PersonalDetails?.city || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>LinkedIn Profile</th>
                          <td>{iddata?.PersonalDetails?.LinkedinProfile || "Not Provided"}</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                  </div>
              {iddata.Language && iddata.Language.length > 0 ? (
                iddata.Language.map((language, index) => (
                  <div key={index}>
                    <table className={classes.detailsTable}>
                      <tbody>
                        <tr>
                          <th>Language</th>
                          <td>{language.LanguageSpoken || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>Level</th>
                          <td>{language.Level || "Not Provided"}</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                  </div>
                ))
              ) : (
                <p>No Language Proficiency Available</p>
              )}
            </div>

            <div className={classes.section}>
              <h4>Advanced Information</h4>
              {iddata.AdditionalDetails ? (
                
                  <div>
                    <table className={classes.detailsTable}>
                      <tbody>
                        <tr>
                          <th>Portfolio/Projects</th>
                          <td>
                            {iddata.AdditionalDetails.portfolio  || "Not Provided"}
                          </td>
                        </tr>
                        <tr>
                          <th>Awards/Recognition</th>
                          <td>{iddata.AdditionalDetails.awards || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>Volunteer Experience</th>
                          <td>{iddata.AdditionalDetails.volunteerexp || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>Publications or Blogs</th>
                          <td>{iddata.AdditionalDetails.publication || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>Personal Interest</th>
                          <td>{iddata.AdditionalDetails.personalIntrest || "Not Provided"}</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                  </div>
                
              ) : (
                <p>No Certifications Available</p>
              )}
            </div>

            <div className={classes.section}>
              <h4>References</h4>
              {referrelArray && referrelArray.length > 0 ? (
               referrelArray.map((item, index) => (
                  <div key={index}>
                    <table className={classes.detailsTable}>
                      <tbody>
                        <tr>
                          <th>Name</th>
                          <td>
                            {item.name || "Not Provided"}
                          </td>
                        </tr>
                        <tr>
                          <th>Designation</th>
                          <td>{item.designation || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>Contact</th>
                          <td>{item.contact || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>Email</th>
                          <td>{item.email || "Not Provided"}</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                  </div>
                ))
              ) : (
                <p>No Certifications Available</p>
              )}
            </div>
            


          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateModal;
