import React, { useEffect, useState } from "react";
//import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./CompanyList.module.css";
import Loader from "react-loader-spinner";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
import LoadingSpinner from "../Components/LoadingSpinner";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import JobModal from "../Components/JobModal/JobModal";
import { BsEye } from "react-icons/bs";
import ExcelDownload from "../Components/Articles/ExcelDownload";
import { CSVLink } from "react-csv";
import ConfirmModal from "../Components/ConfirmModal";
import RemarkModal from "../Components/RemarkModal";
import Paginate from "../Components/Pagination";

const JobList = () => {
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [endDate, setEndDate] = useState("");
  const [modelData, setModelData] = useState("");
  const [orderApproveLoading, setOrderApproveLoading] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [issno, setIssno] = useState("ASC");
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [remarkModal, setremarkModal] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [count, setCount] = useState(0);
  const perPage = 10;
  useEffect(() => {
    JobList();
  }, [currentPage]);

  const JobList = async () => {
    try {
      // Ensure these variables are defined or passed to the function
      // const currentPage = 1; // Example default value
      // const perPage = 10; // Example default value
      // const startDate = "2023-01-01"; // Example default value
      // const endDate = "2023-12-31"; // Example default value

      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
      );

      var formdata = new FormData();
      formdata.append("page", currentPage);
      formdata.append("content", perPage);
      formdata.append("startdate", startDate);
      formdata.append("EndDate", endDate);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      setLoading(true); // Start loading

      const response = await fetch(
        "https://pa6wvshfb4.execute-api.ap-southeast-1.amazonaws.com/staging/adminapi/RecruiterJobList",
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setData(result.data);
      setCount(result.count);
      console.log(result.count);
    } catch (error) {
      console.error("Error:", error);
      // Optionally, display an error message to the user
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const getDetailApi = (recruiterJobUUID) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      recruiterJobUUID: recruiterJobUUID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://pa6wvshfb4.execute-api.ap-southeast-1.amazonaws.com/staging/adminapi/RecruiterJobListbyId",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setModelData(result.data[0]);
        }

        // setCreateDate(result.data.CreatedAt);
        // setUpdateDate(result.data.UpdatedAt);

        // setAuthor();
      })
      .catch((error) => console.log("error", error));
  };

  const AprooveApiNew = (recruiterJobUUID, approve, status, remark) => {
    // Add recruiterJobUUID to the respective loading state based on the action
    setOrderApproveLoading((prev) => [
      ...prev,
      { id: recruiterJobUUID, action: approve },
    ]);

    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      recruiterJobUUID: recruiterJobUUID,
      jobApproved: approve === 1 ? "1" : "0",
      jobStatus: status,
      remark: remark,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://pa6wvshfb4.execute-api.ap-southeast-1.amazonaws.com/staging/adminapi/RecruiterJobApproval",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          JobList(); // Refresh data on success
        }
      })
      .catch((error) => console.log("Error:", error))
      .finally(() => {
        // Remove recruiterJobUUID from the loading state
        setOrderApproveLoading((prev) =>
          prev.filter((item) => item.id !== recruiterJobUUID)
        );
      });
  };

  const sortingsrno = (col) => {
    if (issno === "ASC") {
      const sortsno = [...data].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setData(sortsno);
      setIssno("DSC");
    }
    if (issno === "DSC") {
      const sortsno = [...data].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setData(sortsno);
      setIssno("ASC");
    }
  };

  const onModalCloseHandler = () => {
    setIsModalOpen(false);
  };

  const formValidation = () => {
    let isValid = true;
    let error = {};

    if (!startDate || !endDate) {
      isValid = false;
      error.startDate = "Please select both start and end dates.";
    } else if (new Date(startDate) > new Date(endDate)) {
      isValid = false;
      error.startDate = "Start date cannot be after the end date.";
    }

    setErrors(error);
    return isValid;
  };

  let today = new Date();
  today.setDate(today.getDate() - 60);
  let date = new Date(today)
    .toLocaleDateString("in", {
      day: "2-digit",
      year: "numeric",
      month: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  return (
    <div className={classes.dashboard_container}>
      {remarkModal && (
        <RemarkModal
          heading={`Are you sure you want to ${selectedAction.toLowerCase()} this data?`}
          onRemarkSubmit={(remark) => {
            // Call the AprooveApiNew function with selected action, recruiterUUID, and remark
            if (selectedAction === "Approve") {
              AprooveApiNew(selectedJobId, 1, "Approved", remark);
            } else if (selectedAction === "Reject") {
              AprooveApiNew(selectedJobId, 0, "Rejected", remark);
            }
            setremarkModal(false); // Close the modal after confirming
          }}
          onClose={() => setremarkModal(false)} // Close the modal if canceled
        />
      )}
      {confirmModalShow && (
        <ConfirmModal
          heading={`Are you sure you want to ${selectedAction.toLowerCase()} this data?`}
          onClick={() => {
            // Call the AprooveApiNew function with selected action and recruiterUUID
            if (selectedAction === "Approve") {
              AprooveApiNew(selectedJobId, 1, "Approved");
            } else if (selectedAction === "Reject") {
              AprooveApiNew(selectedJobId, 0, "Rejected");
            }
            setConfirmModalShow(false); // Close the modal after confirming
          }}
          onClose={() => setConfirmModalShow(false)} // Close the modal if canceled
        />
      )}
      <JobModal
        isModalOpen={isModalOpen}
        iddata={modelData}
        setIsModalOpen={setIsModalOpen}
        onClose={onModalCloseHandler}
      />{" "}
      <div className={classes.header}>Posted Job List:</div>
      <div className={classes.DateFilter_container}>
        <div className={classes.button_container}>
          <CSVLink
            data={data ? data : ""}
            filename={"/JobList.csv"}
            target="_blank"
          >
            <button className={classes.button_articleList}>CSV</button>
          </CSVLink>
          <ExcelDownload csvData={data} fileName="/JobList" />
        </div>
        <div className={classes.dateDiv}>
          <div className={classes.filter}>
            <label className={classes.dateLable}>Start Date</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label className={classes.dateLable}>End Date</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className={classes.form_error}>{errors.startDate}</div>
          <div className={classes.buttons}>
            <button
              type="button"
              className="button"
              onClick={() => {
                if (formValidation()) {
                  setLoading(true);
                  JobList();
                } else {
                  console.log("Invalid Date Selection");
                }
              }}
            >
              Search
            </button>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          {/* <div className={classes.container}> */}
          <table className={classes.orders_table}>
            <tr className={classes.table_row}>
              <th className={classes.table_heading}>S.No.</th>
              <th className={classes.table_heading}>
                Company Name
                {issno === "ASC" ? (
                  <span
                    className={classes.arrow}
                    onClick={() => sortingsrno("Job Id")}
                  >
                    <AiOutlineArrowDown />
                  </span>
                ) : (
                  <span
                    className={classes.arrow}
                    onClick={() => sortingsrno("Job Id")}
                  >
                    <AiOutlineArrowUp />
                  </span>
                )}
              </th>
              <th className={classes.table_heading}>Job Title</th>
              {/* <th className={classes.table_heading}>Email</th> */}
              <th className={classes.table_heading}>Remark</th>
              {/* <th className={classes.table_heading}>Location</th> */}
              {/* <th className={classes.table_heading}>Job Name</th> */}
              {/* <th className={classes.table_heading}>Phone No.</th> */}
              <th className={classes.table_heading}>View</th>
              <th className={classes.table_heading}>Action</th>
              <th className={classes.table_heading}>Company Status</th>
              <th className={classes.table_heading}>Job Status</th>
              <th className={classes.table_heading}>Approve</th>
              <th className={classes.table_heading}>Reject/Cancel</th>
            </tr>
            <tbody>
              {data.map((item, index) => {
                return isLoading ? (
                  <div className={classes.spinner}>
                    <LoadingSpinner />
                  </div>
                ) : (
                  <tr className={classes.table_row} key={index}>
                    <td className={classes.table_td}>{(currentPage - 1) * perPage + index + 1}</td>
                    <td className={classes.table_td}>{item.companyName}</td>
                    <td className={classes.table_td}>{item.positionTitle}</td>
                    {/* <td className={classes.table_td}>{item.email}</td> */}
                    <td className={classes.table_tdremark}>{item.remark}</td>
                    {/* <td className={classes.table_td}>{item.location}</td> */}
                    {/* <td className={classes.table_td}>{item.positionTitle}</td> */}
                    {/* <td className={classes.table_td}>{item.mobile}</td> */}

                    <td
                      className={classes.table_td}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setModelData(item);
                        setIsModalOpen(true);
                        getDetailApi(item.recruiterJobUUID);
                      }}
                    >
                      <span className={classes.table_td_Link}>
                        <BsEye />
                      </span>
                    </td>

                    <td className={classes.table_td}>
                      <Link
                        to={"/BiDashboard/EditJobs/" + item.recruiterJobUUID}
                        className={classes.Edit_link_two}
                      >
                        Edit
                      </Link>
                    </td>

                    <td className={classes.table_td}>
                      {item.isAdminApproved === "1" ? "Approved" : "Rejected"}
                    </td>
                    <td className={classes.table_td}>{item.jobStatus}</td>

                    <td className={classes.table_td}>
                      <button
                        onClick={() => {
                          setSelectedAction("Approve");
                          setSelectedJobId(item.recruiterJobUUID);
                          setConfirmModalShow(true);
                        }}
                        className={`${classes.approve_button} ${
                          item.jobStatus !== "" && item.jobStatus !== "Pending"
                            ? classes.disabled_button
                            : ""
                        }`}
                        disabled={
                          orderApproveLoading.some(
                            (loading) =>
                              loading.id === item.recruiterJobUUID &&
                              loading.action === 1
                          ) ||
                          (item.jobStatus !== "" &&
                            item.jobStatus !== "Pending")
                        }
                      >
                        <span className="button-content">
                          {orderApproveLoading.some(
                            (loading) =>
                              loading.id === item.recruiterJobUUID &&
                              loading.action === 1
                          ) ? (
                            <div className="loader-container">
                              <Loader
                                type="TailSpin"
                                color="white"
                                height={15}
                                width={50}
                              />
                            </div>
                          ) : (
                            <>Approve</>
                          )}
                        </span>
                      </button>
                    </td>

                    <td className={classes.table_td}>
                      <button
                        onClick={() => {
                          setSelectedAction("Reject");
                          setSelectedJobId(item.recruiterJobUUID);
                          setremarkModal(true);
                        }}
                        className={`${classes.reject_button} ${
                          item.jobStatus !== "" && item.jobStatus !== "Pending"
                            ? classes.disabled_button
                            : ""
                        }`}
                        disabled={
                          orderApproveLoading.some(
                            (loading) =>
                              loading.id === item.recruiterJobUUID &&
                              loading.action === 0
                          ) ||
                          (item.jobStatus !== "" &&
                            item.jobStatus !== "Pending")
                        }
                      >
                        <span className="button-content">
                          {orderApproveLoading.some(
                            (loading) =>
                              loading.id === item.recruiterJobUUID &&
                              loading.action === 0
                          ) ? (
                            <div className="loader-container">
                              <Loader
                                type="TailSpin"
                                color="white"
                                height={15}
                                width={50}
                              />
                            </div>
                          ) : (
                            <>Disapprove</>
                          )}
                        </span>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div>
            {count > 10 && (
              <Paginate
                maxPage={count}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                perPage={perPage}
              />
            )}
          </div>
          {/* </div> */}
        </div>
      )}
    </div>
  );
};

export default JobList;
