import React, { useState } from "react";

import classes from "../GuestArticle/addguest.module.css";
import EventInputs from "../../Components/Events/EventInputs";
import Modal from "../../Components/Modal";
import { useHistory } from "react-router-dom";
import TimesBreadCrumbs from "../../Components/TimesBreadCrumbs";
import Loader from "react-loader-spinner";

const AddGuest = () => {
  const [articleguestId, setArticleguestId] = useState("");
  const [loading, setLoading] = useState(false);
  const [articleguesttype, setArticleguesttype] = useState("");
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  let history = useHistory();

  const AddGuestApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      articleId: articleguestId,
      articletype: articleguesttype,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/AddForHerArticles",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const guest = [

    {
      type: "Guest",
    },
    {
      type: "ForHerGuest",
    },
    {
      type: "Inspirational stories",
    },
    {
      type: "Hrprofessionnal",
    },
    {
      type: "Courses",
    },
    {
      type: "FeaturedHrArticle",
    },
    {
      type: "Normal",
    },
    {
      type: "GMI",
    },

    {
      type: "AI",
    },
    {
      type: "Finance",
    },
    {
      type: "HrConclave",
    },

    {
      type: "SHEro",
    },
    {
      type: "GlobalMinds",
    },
    {
      type: "LeadersOfChange2024",
    },
    {
      type: "RecruitersIpEdge",
    }
  ];
  const pageInfo = {
    currentPageName: "Add Guest Article",
    previousPageName: "Guest Aricle List",
    previousPage: "/BiDashboard/GuestArticleList",
  };
  const currentURL = window.location.href;

  console.log(currentURL);
  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data inserted successfully"}
          onClick={() => history.push("/BiDashboard/GuestArticleList")}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Are Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.TimsbiBreadcrumb}>
            {" "}
            <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
          </div>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Add Guest Article</div>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"Number"}
                label={"Article Id"}
                placeholder={"Article Id"}
                onChange={(e) => setArticleguestId(e.target.value)}
                value={articleguestId}
              />
            </div>
            <EventInputs
              input={"smalldropdown"}
              label={"Article Type"}
              placeholder={"Article Type"}
              onChange={(e) => setArticleguesttype(e.target.value)}
              displayData={guest}
              value={articleguesttype}
            />
          </div>
        </div>
        <button className={classes.Upload_button} onClick={() => AddGuestApi()}>
          {loading ? (
            <Loader
              type="TailSpin"
              color="white"
              height={20}
              width={50}
              className="text-center"
            />
          ) : (
            "Add"
          )}
        </button>
      </div>
    </div>
  );
};

export default AddGuest;
