import React, { useState, useEffect } from "react";
import classes from "../Keywords/CustomJobContentList.module.css";
import { CSVLink } from "react-csv";
import { BsEye, BsPaperclip } from "react-icons/bs";
import LoadingSpinner from "../Components/LoadingSpinner";
import ExcelDownload from "../Components/Articles/ExcelDownload";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";
import ConfirmModal from "../Components/ConfirmModal";
import Modal from "../Components/Modal";
import CandidateModal from "../Components/Candidate/CandidateModal";

const List = () => {
    const [loading, setLoading] = useState(false);
    const [customContentList, setCustomContentList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [errors, setErrors] = useState(false);
    const [count, setCount] = useState("");
    const [issno, setIssno] = useState("ASC");
    const [modelData, setModelData] = useState("");
    const [confirmModalShow, setConfirmModalShow] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    useEffect(() => {
        CustomJobPageListApi();
    },[]);
    const CustomJobPageListApi = () => {
        setLoading(true);
    
        // Prepare headers
        const myHeaders = new Headers({
            Authorization: "Token 77927b69bb144b065ca11bf2a9d452819cd852db",
            "Content-Type": "application/json",
        });
    
        // Request body
        const raw = JSON.stringify({
            Page: currentPage,
        });
    
        // Request options
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
    
        // Fetch data
        fetch(
          
            "https://pa6wvshfb4.execute-api.ap-southeast-1.amazonaws.com/staging/adminapi/JobsCandidateList",
            requestOptions
        )
            .then((response) => {
                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
                return response.json();
            })
            .then((result) => {
                if (result.status_code === 200) {
                    setCustomContentList(result.data);
                   
              
                } else {
                    console.error("Unexpected response status:", result);
                }
            })
            .catch((error) => {
                console.error("Error during API call:", error);
            })
            .finally(() => setLoading(false));
    };

    const getDetailApi = (candidateUUID) => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
      );
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        candidateUUID: candidateUUID,
      });
  
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
  
      fetch("https://pa6wvshfb4.execute-api.ap-southeast-1.amazonaws.com/staging/adminapi/JobCandidateById",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          
         if (result.status_code === 200) {
        // Set the fetched data in the state
        const mergedData = {
            ...result.data, // contains the main candidate data
            Jobskills: result.Jobskills, // includes job skills
            PersonalDetails: result.PersonalDetails, // includes personal details
            EmploymentDetails: result.EmploymentDetails, // includes employment details
            EducationDetail: result.EducationDetail, // includes education details
            Certifications: result.Certifications, // includes certifications
            Language: result.Language, // includes language proficiency
            AdditionalDetails: result.AdditionalDetails, // additional details
            KeywordName: result.KeywordName, // keyword names
            SkillName: result.SkillName, // skill names
          };
  
          // Set the merged data into the state
          setModelData(mergedData);
          console.log(mergedData, "Merged Data");
        
       
        // Pass result.data to modal state
      }
        })
        .catch((error) => console.log("error", error));
    };
    
    const onModalCloseHandler = () => {
        setIsModalOpen(false);
    };

    const handleDeleteClick = (id) => {
        setDeleteItemId(id);
        setConfirmModalShow(true);
    };

    // const deleteCustomJobContent = () => {
    //     setLoading(true)
    //     if (deleteItemId) {
    //         DeleteApi(deleteItemId);
    //         setConfirmModalShow(false)
    //     }
    // };


    // let today = new Date();
    // today.setDate(today.getDate() - 180);
    // let Keywords = new Date(today)
    //     .toLocaleDateString("in", {
    //         day: "2-digit",
    //         year: "numeric",
    //         month: "2-digit",
    //     })
    //    //.split("/")
    //     .reverse()
    //     .join("-");

    const pageInfo = {
        currentPageName: "Candidate List",
        previousPageName: "",
        previousPage: "",
    };
    // const formatDate = (dateString) => {
    //     const [date, time] = dateString
    //     //const timeShort = time.split(":").slice(0, 2).join(":");
    //     return `${date} ${timeShort}`;
    // };
    return (
        <>

        <CandidateModal
        isModalOpen={isModalOpen}
        iddata={modelData}
        setIsModalOpen={setIsModalOpen}
        onClose={onModalCloseHandler}
        />{" "}
            {/* Confirm Deletion Modal */}
            {confirmModalShow && (
                <ConfirmModal
                    heading={"Are you sure that you want to delete this data?"}
                    //onClick={deleteCustomJobContent} // Call delete handler on confirmation
                    onClose={() => setConfirmModalShow(false)} // Close modal on cancel
                />
            )}

            {/* Success Modal */}
            {modalShow && (
                <Modal
                    heading={"Data deleted successfully"}
                    onClick={() => {
                        setModalShow(false);
                        setConfirmModalShow(false);
                    }}
                />
            )}
            {/* <ViewContentModal
                isModalOpen={isModalOpen}
                data={modelData}
                setIsModalOpen={setIsModalOpen}
                onClose={onModalCloseHandler}
            /> */}
            <div className={classes.dashboard_container}>
                <div className={classes.TimsbiBreadcrumb}>
                    <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
                </div>
                <div className={classes.DateFilter_container}>
                    <div className={classes.button_container}>
                        <CSVLink
                            data={customContentList}
                            filename={"CandidateList.csv"}
                            target="_blank"
                        >
                            <button className={classes.button_articleList}>CSV</button>
                        </CSVLink>
                        <ExcelDownload csvData={customContentList} fileName="CandidateList" />
                    </div>
                </div>
                {loading ? (
                    <div className={classes.spinner}>
                        <LoadingSpinner />
                    </div>
                ) : (
                    <div className={classes.table_container}>
                        <div className={classes.buttons1}>
                            <div>
                                {currentPage !== 1 ? (
                                    <button
                                        onClick={() => {
                                            setCurrentPage(currentPage - 1);
                                        }}
                                        className={classes.buttonone}
                                    >
                                        Prev
                                    </button>
                                ) : (
                                    <></>
                                )}
                                <span>{count >= 20 ? currentPage : <></>}</span>
                                {customContentList.length >= 20 ? (
                                    <button
                                        onClick={() => {
                                            setCurrentPage(currentPage + 1);
                                        }}
                                        className={classes.buttonone}
                                    >
                                        Next
                                    </button>
                                ) : (
                                    <></>
                                )}
                            </div>

                            {/* <div className={classes.customButtons}>

                                <button className={classes.Addbtn}>
                                    <Link
                                        to="/BiDashboard/AddKeyWord"
                                        className={classes.Edit_link}
                                    >
                                        Add New Key Words
                                    </Link>
                                </button>
                                <button className={classes.Addbtn}>
                                    <Link
                                        to="/BiDashboard/FaqInsert"
                                        className={classes.Edit_link}
                                    >
                                        Add FAQ
                                    </Link>
                                </button>
                            </div> */}

                        </div>
                        <div className={classes.container}>
        <table className={classes.orders_table}>
            {/* Table header */}
            <thead>
                <tr className={classes.table_row}>
                    <th className={classes.table_heading}>S.No.</th>
                    <th className={classes.table_heading}>Name</th>
                    <th className={classes.table_heading}>Keyword Name</th>
                    <th className={classes.table_heading}>Email</th>
                    <th className={classes.table_heading}>Mobile</th>
                    <th className={classes.table_heading}>Job Title</th>
                    <th className={classes.table_heading}>Resume Url</th>
                    <th className={classes.table_heading}>View</th>
                   
                </tr>
            </thead>

            {/* Table body with data rows */}
            <tbody>
                {customContentList.length > 0 ? (
                    customContentList.map((item, index) => (
                        <tr key={item.CustomJobPageUUID} className={classes.table_row}>
                            <td className={classes.table_td}>{index + 1}</td>
                            <td className={classes.table_td}>{item.firstName} {item.lastName}</td>
                            <td className={classes.table_td}>{item.keywordName}</td>
                            <td className={classes.table_td}>{item.email}</td>
                            <td className={classes.table_td}>{item.mobile}</td>
                            <td className={classes.table_td}>{item.JobTitle}</td>
                            <td className={classes.table_td}>
  <a href={item.resumeURL} download target="_blank" rel="noopener noreferrer">
    <BsPaperclip />
  </a>
</td>


                          
                           {/* <td className={classes.table_td}>{formatDate(item.CreatedAt)}</td> */}
                          
                           <td
  className={classes.table_td}
  style={{ cursor: "pointer" }}
  onClick={() => {
    setModelData(item);
    setIsModalOpen(true);
    getDetailApi(item.candidateUUID); // Pass candidateUUID dynamically
  }}
>
  <span className={classes.table_td_Link}>
    <BsEye />
  </span>
</td>

                            {/* <td className={classes.table_td}>
                                <div
                                    className={classes.customLinkDelete}
                                    onClick={() => DeleteApi(item.keywordUUID)}
                                >
                                    {loading ? (
                                        <Loader
                                            type="TailSpin"
                                            color="white"
                                            height={20}
                                            width={50}
                                            className="text-center"
                                        />
                                    ) : (
                                        <MdDeleteForever size={20} />
                                    )}
                                </div>
                            </td> */}
                        </tr>
                    ))
                ) : (
                    <tr className={classes.No_data_row}>
                        <td colSpan="8" className={classes.No_data}>
                            No data found!
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>

                    </div>
                )}
            </div >
        </>
    );
};

export default List;
