import React, { useRef, useState } from "react";

import classes from "../Webstory/addwebstory.module.css";
import EventInputs from "../Events/EventInputs";
import Modal from "../../Components/Modal";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import TimesBreadCrumbs from "../TimesBreadCrumbs";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const AddWebstory = () => {
  const latestRequestId = useRef(0);
  const controllerRef = useRef(null);
  const [isSeoUrlValid, setIsSeoUrlValid] = useState(false);
  const [seoUrlValidationMessage, setSeoUrlValidationMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [check, setCheck] = useState(0);
  const [errorShow, setErrorShow] = useState(false);
  const [type, setType] = useState("Article");
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [ID, setId] = useState("");
  const history = useHistory();
  const [isSeoUrlEditable, setIsSeoUrlEditable] = useState(false);
  const [seoUrl, setSeoUrl] = useState("");



  const generateSeoUrl = (webstoryType, title) => {
    return `web-stories/${webstoryType?.toLowerCase()
      .replace(/[^a-z0-9\s-]/gi, "")
      .replace(/\s+/g, "-")
      .replace(/--+/g, "-")
      .replace(/^-|-$/g, "")
      .toLowerCase()
      .trim()}/${title
        ?.replace(/[^a-z0-9\s-]/gi, "")
        .replace(/\s+/g, "-")
        .replace(/--+/g, "-")
        .replace(/^-|-$/g, "")
        .toLowerCase()
        .trim()}`;
  };

  const handleSeoUrlChange = (value) => {
    setSeoUrl(value);

    if (true) {
      setSeoUrlValidationMessage("");
      setIsSeoUrlValid(true);
      handleSeoUrlValidation(value);
    } else {
      setSeoUrlValidationMessage(
        "Invalid SeoURL. Use lowercase letters, numbers, starts with articles/ and single dashes."
      );
      setIsSeoUrlValid(false);
    }
  };



  const handleTitleChange = (value) => {
    setTitle(value);
    if (value && type) {
      const generatedSeoUrl = generateSeoUrl(type, value);
      setSeoUrl(generatedSeoUrl);
      setIsSeoUrlEditable(false);
      handleSeoUrlValidation(generatedSeoUrl);
    } else {
      setSeoUrl("");
      setIsSeoUrlEditable(false);
      setSeoUrlValidationMessage("");
    }
  };


  const handleWebstoryTypeChange = (value) => {
    setType(value);
    if (title) {
      const generatedSeoUrl = generateSeoUrl(value, title);
      setSeoUrl(generatedSeoUrl);
      setIsSeoUrlEditable(false);
      handleSeoUrlValidation(generatedSeoUrl);
    }
  };

  const handleSeoUrlValidation = async (value) => {
    const requestId = ++latestRequestId.current;

    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();

    if (!value) {
      setIsSeoUrlValid(false);
      setSeoUrlValidationMessage("SEO URL cannot be empty.");
      return;
    }

    const seoUrlPrefix = "web-stories/";
    if (!value.startsWith(seoUrlPrefix)) {
      setIsSeoUrlValid(false);
      setSeoUrlValidationMessage(
        `Invalid SEO URL. Ensure it starts with '${seoUrlPrefix}'`
      );
      return;
    }

    const slugPattern = /^[a-z0-9]+(-[a-z0-9]+)*$/;
    const [, webstoryType, slug] = value.match(/^web-stories\/([^/]+)\/(.+)$/) || [];

    if (!webstoryType || !slug || !slugPattern.test(slug)) {
      setIsSeoUrlValid(false);
      setSeoUrlValidationMessage(
        "Invalid SEO URL. Ensure it is in the format 'web-stories/{webstoryType}/{slug}'."
      );
      return;
    }

    const requestBody = { SeoURL: value };
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Token 77927b69bb144b065ca11bf2a9d452819cd852db",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
      signal: controllerRef.current.signal,
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/adminapi/CheckSeoURLinWebstories",
        requestOptions
      );
      const result = await response.json();

      if (requestId === latestRequestId.current) {
        if (result.status_code === 200 && result.status === "SUCCESS") {
          if (result.IsSeoURLExist) {
            setIsSeoUrlValid(false);
            setSeoUrlValidationMessage("SEO URL already exists. Try a unique one.");
          } else {
            setIsSeoUrlValid(true);
            setSeoUrlValidationMessage("SEO URL is valid.");
          }
        } else {
          setIsSeoUrlValid(false);
          setSeoUrlValidationMessage("Error validating SEO URL.");
        }
      }
    } catch (error) {
      if (error.name !== "AbortError" && requestId === latestRequestId.current) {
        setSeoUrlValidationMessage("Error validating SEO URL.");
      }
    }
  };




  const AddWebstory = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("Title", title);
    formdata.append("Description", description);
    formdata.append("Image", image);
    formdata.append("IsLive", check);
    formdata.append("SeoUrl", seoUrl);
    formdata.append("MetaDescription", metaDescription);
    formdata.append("MetaTitle", metaTitle);
    formdata.append("WebStoryType", type);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/v1/api/apiTimes/AddWebstoriesSummary",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setId(result.ID);
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const pageInfo = {
    currentPageName: "Add Webstory",
    previousPageName: "WebStory List",
    previousPage: "/BiDashboard/webstorylist",
  };

  const webstoriesTypeOptions = [
    {
      type: "Article",
    },
    {
      type: "SHEro",
    },
    {
      type: "Technology & Innovation",
    },
    {
      type: "Education & Learning",
    },
    {
      type: "Relationships & Family",
    },
    {
      type: "Lifestyle & Wellness",
    },
    {
      type: "Money matters",
    },
    {
      type: "Business & Management",
    },
    {
      type: "Personal Branding & Networking",
    },
    {
      type: "Leadership & Management Skills",
    },
    {
      type: "HR Initiatives",
    },
    {
      type: "Career Development & Upskilling",
    },
  ];

  //   const imageHandlerChange = (e) => {
  //     if (e.target.files) {
  //       const fileArray = Array.from(e.target.files).map((file) =>
  //         URL.createObjectURL(file)
  //       );
  //       setUpload(fileArray);
  //       setUploadUrls(e.target.files);
  //     }
  //   };

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data inserted successfully"}
          onClick={() => history.push("/BiDashboard/AddWebstorySummary/" + ID)}
        />
      )}
      {errorShow && (
        <Modal
          heading={"Required Fields Are Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.TimsbiBreadcrumb}>
            {" "}
            <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
          </div>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Add Webstory</div>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input="SingleLineInput"
                label="Title"
                placeholder="Title"
                onChange={(e) => handleTitleChange(e.target.value)}
                value={title}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Meta Title"}
                placeholder={"Meta Title"}
                maxLength={70}
                onChange={(e) => setMetaTitle(e.target.value)}
                value={metaTitle}
              />
              <div
                style={{
                  display: "flex",
                  fontSize: "12px",
                  justifyContent: "space-between",
                  padding: "0px 12px",
                  alignItems: "center",
                }}
              >
                <div>
                  Character count {metaTitle?.length ? metaTitle?.length : "0"}
                </div>
                <div>(Maximum 70 Characters)</div>
              </div>
              <EventInputs
                input={"SingleLineInput"}
                label={"Description"}
                placeholder={"Description"}
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
              <EventInputs
                input={"ImageInput"}
                label={"Card Image"}
                placeholder={"Card Image"}
                onChange={(e) => setImage(e.target.files[0])}
              />
            </div>

            <div className={classes.input}>
              <EventInputs
                input="smalldropdown"
                label="Webstory Type"
                placeholder="Webstory Type"
                onChange={(e) => handleWebstoryTypeChange(e.target.value)}
                displayData={webstoriesTypeOptions}
                value={type}
              />
              <EventInputs
                input={"SingleLineInput"}
                maxLength={170}
                label={"Meta Description"}
                placeholder={"Meta Description"}
                onChange={(e) => setMetaDescription(e.target.value)}
                value={metaDescription}
              />
              <div
                style={{
                  display: "flex",
                  fontSize: "12px",
                  justifyContent: "space-between",
                  padding: "0px 12px",
                  alignItems: "center",
                }}
              >
                <div>
                  Character count{" "}
                  {metaDescription?.length ? metaDescription?.length : "0"}
                </div>
                <div>(Maximum 170 Characters)</div>
              </div>
              <EventInputs
                input="SingleLineInput"
                label="SEO URL"
                placeholder="SEO URL"
                onChange={(e) => handleSeoUrlChange(e.target.value)} // Use the new function
                value={seoUrl}
              />


              <p>{seoUrlValidationMessage}</p>
            </div>
          </div>
          <button
            className={classes.Upload_button}
            onClick={() => AddWebstory()}
          >
            {loading ? (
              <Loader
                type="TailSpin"
                color="white"
                height={20}
                width={50}
                className="text-center"
              />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddWebstory;
