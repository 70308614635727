import React, { useState, useEffect } from "react";

import classes from "./insertbanner.module.css";
import EventInputs from "../../Components/Events/EventInputs";
import { useHistory } from "react-router-dom";
import TimesBreadCrumbs from "../../Components/TimesBreadCrumbs";
import Loader from "react-loader-spinner";
import Modal from "../../Components/Modal";

const Insertbanner = () => {
  const [sno, setSno] = useState("");
  const [loading, setLoading] = useState("");
  const [pageName, setPageName] = useState("");
  const [desktopImage, setDesktopImage] = useState("");
  const [mobileImage, setMobileImage] = useState("");
  const [desktopurl, setDesktopurl] = useState("");
  const [mobileurl, setMobileurl] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [check, setCheck] = useState(0);
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [BannerImageAltTag, setBannerImageAltTag] = useState("")

  let history = useHistory();

  const InsertbannerApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();

    formdata.append("startDate", startDate);
    formdata.append("Enddate", endDate);
    formdata.append("serialNumber", sno);
    formdata.append("pageName", pageName);
    formdata.append("desktopImageURL", desktopImage);
    formdata.append("mobileImageURL", mobileImage);
    formdata.append("redirectionDesktopUrl", desktopurl);
    formdata.append("redirectionMobileUrl", mobileurl);
    formdata.append("isPublished", check);
    formdata.append("BannerImageAltTag ", BannerImageAltTag);


    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/AddBannerImage",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const pageInfo = {
    currentPageName: "Insert Banner Image",
    previousPageName: "Banner Image List",
    previousPage: "/BiDashboard/BannerImageList",
  };
  const guest = [
    {
      type: "Wealth",
    },
    {
      type: "Career",
    },
    {
      type: "WeCare",
    },
    {
      type: "Web",
    },
    {
      type: "MovementofProfessionals",
    },
    {
      type: "Mentorship",
    },
    {
      type: "jobs",
    },
    {
      type: "Hr",
    },
    {
      type: "Home Page",
    },
    {
      type: "GMI",
    },
    {
      type: "ForHer",
    },
    {
      type: "Events",
    },
    {
      type: "CXO",
    },
    {
      type: "Courses",
    },
    {
      type: "HrConclave",
    },
    {
      type: "SHEro",
    },
    {
      type: "LeadersOfChange2024",
    },
    {
      type: "Quiz",
    },
    {
      type: "RecruitersIpEdge",
    },

  ]
  const handleSelectChange = (e) => {
    setSelectedType(e.target.value);
    setPageName(e.target.value);
  };

  function formatDate(date) {
    if (!date) return "";

    const parsedDate = new Date(date);
    if (isNaN(parsedDate)) return "";

    // Adjust the date to account for the timezone offset
    const timeZoneOffset = parsedDate.getTimezoneOffset() * 60000; // Convert offset to milliseconds
    const adjustedDate = new Date(parsedDate.getTime() - timeZoneOffset);

    // Format the date to a string in the required format
    const formattedDate = adjustedDate.toISOString().slice(0, 16);
    return formattedDate;
  }

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data inserted successfully"}
          onClick={() => history.push("/BiDashboard/BannerImageList")}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Are Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.TimsbiBreadcrumb}>
            {" "}
            <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
          </div>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Insert Banner Image</div>
            <div className={classes.checkbox}>
              <span className={classes.live}>Live</span>
              <EventInputs
                input={"CheckBox"}
                onChange={() => setCheck(!check === true ? 1 : 0)}
                value={check}
              />
            </div>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"Number"}
                label={"Serial Number"}
                placeholder={"Serial Number"}
                onChange={(e) => setSno(e.target.value)}
                value={sno}
              />

              <EventInputs
                input={"ImageInput"}
                label={"Desktop Image"}
                placeholder={"Desktop Image"}
                onChange={(e) => setDesktopImage(e.target.files[0])}
              />

              <EventInputs
                input={"SingleLineInput"}
                label={"Redirection Desktop Url"}
                placeholder={"Redirection Desktop Url"}
                onChange={(e) => setDesktopurl(e.target.value)}
                value={desktopurl}
              />
            </div>

            <div className={classes.input}>
              <div className={classes.dropdown}>
                <label htmlFor="eventType">Select Page Name:</label>
                <select
                  className={classes.drop}
                  id="eventType"
                  onChange={handleSelectChange}
                  value={selectedType}
                >
                  <option value="">Select an page name</option>
                  {guest.map((event, index) => (
                    <option key={index} value={event.type}>
                      {event.type}
                    </option>
                  ))}
                </select>

                {/* Display the selected value */}
              </div>
              <EventInputs
                input={"ImageInput"}
                label={"Mobile Image"}
                placeholder={"Mobile Image"}
                onChange={(e) => setMobileImage(e.target.files[0])}
              />

              <EventInputs
                input={"SingleLineInput"}
                label={"Redirection Mobile Url"}
                placeholder={"Redirection Mobile Url"}
                onChange={(e) => setMobileurl(e.target.value)}
                value={mobileurl}
              />
            </div>
          </div>
          <div>
            <div className={classes.date}>
              <div className={classes.dropdown}>
                <label className={classes.dateLabel}>Start Date</label>
                <input
                  className={classes.drop}
                  type="datetime-local"
                  value={formatDate(startDate)}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>

              <div className={classes.dropdown}>
                <label className={classes.dateLabel}>End Date</label>
                <input
                  className={classes.drop}
                  type="datetime-local"
                  value={formatDate(endDate)}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
            <div className={classes.form_input_new}>
              <div className={classes.input}>
                <EventInputs
                  input={"SingleLineInput"}
                  label={"Image Alt Tag"}
                  placeholder={"Image Alt Tag"}
                  onChange={(e) => setBannerImageAltTag(e.target.value)}
                  value={BannerImageAltTag}
                />
              </div>
            </div>
          </div>
        </div>

        <button
          className={classes.Upload_button}
          onClick={() => InsertbannerApi()}
        >
          {loading ? (
            <Loader
              type="TailSpin"
              color="white"
              height={20}
              width={50}
              className="text-center"
            />
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </div>
  );
};

export default Insertbanner;
