import React, { useState, useEffect } from "react";
import classes from "./Cources.module.css";
import { Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../Components/LoadingSpinner";
import ExcelDownload from "../Components/Articles/ExcelDownload";
import { CSVLink } from "react-csv";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
import { BsEye, BsPencilSquare } from "react-icons/bs";
const Cources = (props) => {
  let today = new Date();
  today.setDate(today.getDate() - 60);
  let date = new Date(today)
    .toLocaleDateString("in", {
      day: "2-digit",
      year: "numeric",
      month: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(
    new Date()
      .toLocaleDateString("in", {
        day: "2-digit",
        year: "numeric",
        month: "2-digit",
      })
      .split("/")
      .reverse()
      .join("-")
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [isdate, setIsdate] = useState("ASC");
  const [isname, setIsname] = useState("ASC");
  const [isprovider, setIsprovider] = useState("ASC");
  const [count, setCount] = useState("");
  const [livecount, setlivecount] = useState("");
  const [unlivecount, setunlivecount] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const history = useHistory();
  const AddEventsHandler = () => {
    history.push("/BiDashboard/addcourses");
  };

  useEffect(() => {
    CourseList();
  }, [currentPage]);

  const CourseList = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      start_date: startDate,
      end_date: endDate,
      page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      global.apiLink +"/adminapi/course-admin",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
        setFilteredData(result.data);
        setCount(result.count);
        setlivecount(result.live_count);
        setunlivecount(result.unlive_count);
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };
  const sortingdate = (col) => {
    if (isdate === "ASC") {
      const sorteddate = [...data].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setData(sorteddate);
      setIsdate("DSC");
    }
    if (isdate === "DSC") {
      const sorteddate = [...data].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setData(sorteddate);
      setIsdate("ASC");
    }
  };
  const sortingName = (col) => {
    if (isname === "ASC") {
      const sortedname = [...data].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setData(sortedname);
      setIsname("DSC");
    }
    if (isname === "DSC") {
      const sortedname = [...data].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setData(sortedname);
      setIsname("ASC");
    }
  };
  const sortingprovider = (col) => {
    if (isprovider === "ASC") {
      const sortedprovider = [...data].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setData(sortedprovider);
      setIsprovider("DSC");
    }
    if (isprovider === "DSC") {
      const sortedprovider = [...data].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setData(sortedprovider);
      setIsprovider("ASC");
    }
  };
  const filterdata = (status) => {
    if (status === "1") {
      const filtereddata = data.filter((item) => {
        if (item.status === "1") {
          return item;
        }
      });
      setFilteredData(filtereddata);
    }
  };

  const filterunlive = (status) => {
    if (status === "0") {
      const unlivedata = data.filter((item) => {
        if (item.status === "0") {
          return item;
        }
      });
      setFilteredData(unlivedata);
    }
  };
  return (
    <div className={classes.dashboard_container}>
      <div className={classes.table_container}>
        <div className={classes.container}>
          {props.accessLevel === "TIMES" ? (
            ""
          ) : (
            <div className={classes.button_wrapper}>
              <button
                onClick={AddEventsHandler}
                className={classes.Add_event_button}
              >
                Add Courses
              </button>
            </div>
          )}{" "}
          <h3 className={classes.events_title}>Courses</h3>
          <div className={classes.DateFilter_container}>
            <div className={classes.button_container}>
              <CSVLink
                data={data}
                filename={"Course-table.csv"}
                target="_blank"
              >
                <button className={classes.button_articleList}>CSV</button>
              </CSVLink>
              <ExcelDownload csvData={data} fileName="Course-table" />
            </div>
            <div className={classes.dateDiv}>
              <div>
                <label className={classes.dateLable}>Start Date</label>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                <label className={classes.dateLable}>End Date</label>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
              <div className={classes.buttons}>
                <button
                  type="button"
                  className="button"
                  onClick={() => CourseList()}
                >
                  {" "}
                  Search
                </button>
              </div>
            </div>
          </div>
          <div className={classes.headerPageNavigation}>
            <div className={classes.buttons1}>
              {currentPage > 0 ? (
                <button
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                  }}
                >
                  Prev
                </button>
              ) : (
                <></>
              )}
              <span>{currentPage}</span>
              <button
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
              >
                Next
              </button>
            </div>

            <div>
              <button
                onClick={() => {
                  filterdata("1");
                }}
              >
                Live
              </button>

              <button
                onClick={() => {
                  filterunlive("0");
                }}
              >
                Unlive
              </button>

              <button
                onClick={() => {
                  CourseList();
                }}
              >
                All
              </button>
            </div>
            <div className={classes.card}>
              <div className={classes.count}>Count : {count}</div>
              <div className={classes.livecount}>Live Count : {livecount}</div>
              <div className={classes.unlivecount}>
                Unlive Count : {unlivecount}
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className={classes.spinner}>
              <LoadingSpinner />{" "}
            </div>
          ) : (
            <table className={classes.events_table}>
              <tr className={classes.table_row}>
                <th className={classes.table_heading}>Course Image</th>
                <th className={classes.table_heading}>
                  Course Name
                  {isname === "ASC" ? (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingName("course_title")}
                    >
                      <AiOutlineArrowDown />
                    </span>
                  ) : (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingName("course_title")}
                    >
                      <AiOutlineArrowUp />
                    </span>
                  )}
                </th>
                <th className={classes.table_heading}>
                  Provider Name
                  {isprovider === "ASC" ? (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingprovider("providerName")}
                    >
                      <AiOutlineArrowDown />
                    </span>
                  ) : (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingprovider("providerName")}
                    >
                      <AiOutlineArrowUp />
                    </span>
                  )}
                </th>
                {/* <th className={classes.table_heading}>Seo URL</th> */}
                <th className={classes.table_heading}>Course Duration</th>
                <th className={classes.table_heading}>
                  Course Created
                  {isdate === "ASC" ? (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingdate("course_created_at")}
                    >
                      <AiOutlineArrowDown />
                    </span>
                  ) : (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingdate("course_created_at")}
                    >
                      <AiOutlineArrowUp />
                    </span>
                  )}
                </th>
                <th className={classes.table_heading}>Status</th>
                <th className={classes.table_heading}>View</th>
                {props.accessLevel === "TIMES" ? (
                  ""
                ) : (
                  <th className={classes.table_heading}>Edit</th>
                )}
              </tr>

              <tbody>
                {filteredData.map((item) => (
                  <tr className={classes.table_row}>
                    <td className={classes.table_td}>
                      <img
                        src={item.course_image}
                        className={classes.table_td_image}
                        alt=""
                      />
                    </td>
                    <td className={classes.table_td}>{item.course_title}</td>
                    <td className={classes.table_td}>{item.providerName}</td>
                    {/* <td className={classes.table_td}>{item.SeoURL}</td> */}
                    <td className={classes.table_td}>{item.courseDuration}</td>
                    <td className={classes.table_td}>
                      {item.course_created_at.slice(0, 10)}
                    </td>
                    <td className={classes.table_td}>
                      {item.status == 1 ? "Live" : "Not published"}
                    </td>
                    <td className={classes.table_td}>
                      <Link
                        to={"/BiDashboard/coursedetail/" + item.id}
                        className={classes.table_td_Link}
                      >
                        <BsEye />
                      </Link>
                    </td>
                    {props.accessLevel === "TIMES" ? (
                      ""
                    ) : (
                      <td className={classes.table_td}>
                        <Link
                          to={"/BiDashboard/updatecourses/" + item.id}
                          className={classes.table_td_Link}
                        >
                          <BsPencilSquare />
                        </Link>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cources;
