import { GoogleLogin } from "@react-oauth/google";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import classes from "./SignInWithGoogle.module.css";
// import { GoogleLogin } from "react-google-login";
// import FacebookLogin from 'react-facebook-login';
// import { gapi } from "gapi-script";
const SignInWithGoogle = () => {
  const [error, setError] = useState(false);

  const onSuccess = (res) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Token: res.credential,
      RequestUrl: "url",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL +
      "/v1/api/apiTimes/BIAdminAutheri",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "SUCCESS") {
          // result.data.authorized ? history.push("/Routing") : setError(true)

          sessionStorage.setItem("googleBiEmail", result.data.email);
          sessionStorage.setItem("Token", res.credential);
          history.push("/BiDashboard/Dashboard");
        } else {
          setError(true);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const onFailure = (res) => {
    console.log(res);
  };

  const history = useHistory();

  // gapi.load("client:auth2", () => {
  //   gapi.client.init({
  //     clientId:
  //       "996194651413-4dhbbg0dv6hvahi0iafbaf5m6f0jl9c1.apps.googleusercontent.com",
  //     plugin_name: "chat",
  //   });
  // });
  const responseMessage = (response) => {
    console.log(response);
  };
  const errorMessage = (error) => {
    console.log(error);
  };
  return (
    <div className={classes.container}>
      <div className={classes.web_view}>
        {/* <GoogleLogin
          className={classes.button}
          // live clientid
          clientId="996194651413-4dhbbg0dv6hvahi0iafbaf5m6f0jl9c1.apps.googleusercontent.com"
          // live clientid

          //clientId="148730292611-q449eq3pm3tokgp1fgntkn0jf6ebuvpt.apps.googleusercontent.com"
          buttonText="Login"
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={"single_host_origin"}
          isSignedIn={true}
        /> */}
        {/* <FacebookLogin
        appId="585810205866055"
        autoLoad={true}
        fields="name,email,picture"
        onClick={componentClicked}
        callback={responseFacebook} /> */}{" "}
        <GoogleLogin onSuccess={onSuccess} onError={errorMessage} />
        <div className={classes.version}>V1.0.61</div>
        <div>
          {error && (
            <div>
              <h2 className={classes.error}>Error Code: 007</h2>
              <p className={classes.error_text}>
                please login with authorized id
              </p>
            </div>
          )}
        </div>
      </div>
      <div className={classes.mob_view}>
        <h1 className={classes.mobView_text}>please open on Web</h1>
      </div>
    </div>
  );
};
export default SignInWithGoogle;
