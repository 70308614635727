import React from "react";
import classes from "./CompanyModal.module.css";
import { FaCopy } from "react-icons/fa";

const CompanyModal = (props) => {
    console.log(props, "propsData");
  if (!props.isModalOpen) {
    return null;
  }

  const copyToClipboard = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert("URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const { iddata } = props;

  return (
    <>
    <div className={classes.overlay} onClick={props.onClose}></div>
    <div className={classes.modal}>
      {/* Modal Header */}
      <div className={classes.header}>
        <h2>Company Details</h2>
        <button className={classes.closeButton} onClick={props.onClose}>
          &times;
        </button>
      </div>
    
      {/* Modal Content */}
      <div className={classes.contentWrapper}>
        {/* Left Section: Image and Personal Details */}
        <div className={classes.leftSection}>
          <h4>Personal Details</h4>
          <div className={classes.section}>
            <table className={classes.detailsTable}>
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>{`${iddata.firstName || 'Not Provided'} ${iddata.lastName || ''}`}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{iddata.email || 'Not Provided'}</td>
                </tr>
                <tr>
                  <th>Mobile</th>
                  <td>{iddata.mobile || 'Not Provided'}</td>
                </tr>
                <tr>
                  <th>Company Name</th>
                  <td>{iddata.companyName || 'Not Provided'}</td>
                </tr>
                <tr>
                  <th>Company Email</th>
                  <td>{iddata.companyemail || 'Not Provided'}</td>
                </tr>
                <tr>
                  <th>Website</th>
                  <td>
                    {iddata.website ? (
                      <a href={iddata.website} target="_blank" rel="noopener noreferrer">
                        {iddata.website}
                      </a>
                    ) : (
                      'Not Provided'
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Contact No</th>
                  <td>{iddata.contactNo || 'Not Provided'}</td>
                </tr>
                <tr>
                  <th>Industry</th>
                  <td>{iddata.industry || 'Not Provided'}</td>
                </tr>
                <tr>
                  <th>Company Description</th>
                  <td>{iddata.companyDescription || 'Not Provided'}</td>
                </tr>
                <tr>
                  <th>Company Address</th>
                  <td>{iddata.companyAddress || 'Not Provided'}</td>
                </tr>
                <tr>
                  <th>Location</th>
                  <td>{iddata.location || 'Not Provided'}</td>
                </tr>
                {/* <tr>
                  <th>Admin Approval</th>
                  <td>{iddata.isAdminApproved === "1" ? "Approved" : "Not Approved"}</td>
                </tr>
                <tr>
                  <th>Company Approval Status</th>
                  <td>{iddata.isApproved || 'Not Provided'}</td>
                </tr> */}
                <tr>
                  <th>Company Logo</th>
                  <td>
                    {iddata.companyLogo ? (
                      <img src={iddata.companyLogo} alt="Company Logo" />
                    ) : (
                      'Not Provided'
                    )}
                  </td>
                </tr>
                <tr>
                  <th>PAN Card Image</th>
                  <td>
                    {iddata.panCardImageUrl ? (
                      <a href={iddata.panCardImageUrl} target="_blank" rel="noopener noreferrer">
                        View Document
                      </a>
                    ) : (
                      'Not Provided'
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    
        {/* Right Section */}
        {/* <div className={classes.rightSection}>
          <div className={classes.section}>
            <h4>Job Application Details</h4>
            <table className={classes.detailsTable}>
              <tbody>
                <tr>
                  <th>Job Description</th>
                  <td>{iddata.jobApplicationDescription || 'Not Provided'}</td>
                </tr>
                <tr>
                  <th>Application URL</th>
                  <td>
                    {iddata.applicationUrl ? (
                      <a href={iddata.applicationUrl} target="_blank" rel="noopener noreferrer">
                        {iddata.applicationUrl}
                      </a>
                    ) : (
                      'Not Provided'
                    )}
                  </td>
                </tr>
                <tr>
                  <th>How to Apply</th>
                  <td>{iddata.howToApply || 'Not Provided'}</td>
                </tr>
                <tr>
                  <th>Application Deadline</th>
                  <td>{iddata.applicationDeadline || 'Not Provided'}</td>
                </tr>
                <tr>
                  <th>Job Status</th>
                  <td>{iddata.jobStatus || 'Not Provided'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}
      </div>
    </div>
    </>
    
  );
};

export default CompanyModal;
