import { Fragment, useState } from "react";
import classes from "./RemarkModal.module.css";

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onClose} />;
};

const RemarkModal = (props) => {
  const [remark, setRemark] = useState("");

  const handleRemarkChange = (event) => {
    setRemark(event.target.value);
  };

  const handleOkClick = () => {
    if (props.onRemarkSubmit) {
      props.onRemarkSubmit(remark);
    }
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <Fragment>
      <Backdrop onClose={props.onClose} />
      <div className={classes.modal} role="dialog" aria-labelledby="modal-heading" aria-modal="true">
        <h2 id="modal-heading" className={classes.heading}>
          {props.heading}
        </h2>
        <div className={classes.textareaWrapper}>
          <textarea
            className={classes.textarea}
            placeholder="Enter your remark here..."
            value={remark}
            onChange={handleRemarkChange}
            rows="6"
            aria-label="Remark input"
          />
        </div>
        <div className={classes.buttonWrapper}>
          <button className={classes.button} onClick={handleOkClick}>
            OK
          </button>
          <button className={classes.button} onClick={props.onClose}>
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default RemarkModal;
