import React, { useState, useEffect, useRef } from "react";
import EventInputs from "../Events/EventInputs";
import classes from "./AddCourses.module.css";
import { Link, useHistory } from "react-router-dom";
import BackButton from "../BackButton";
import Loader from "react-loader-spinner";
import Modal from '../Modal'
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import TextEditor from "../TextEditor";
import ErrorModal from "../ErrorModal";

const AddCourses = () => {
  const history = useHistory()
  const [title, setTitle] = useState();
  const [category, setCategory] = useState([]);
  const [description, setDescription] = useState();
  const [passLoader, setPassLoader] = useState();
  const [type, setType] = useState("Free Course");
  const [source, setSource] = useState();
  const [url, setUrl] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState()
  const [courseImage, setCourseImage] = useState();
  const [industry, setIndustry] = useState();
  const [courseProvider, setCourseProvider] = useState();
  const [courseGroup, setCourseGroup] = useState("Free");
  const [duration, setDuration] = useState();
  const [courseProviderLogo, setCourseProviderLogo] = useState();
  const [providerName, setProviderName] = useState([]);
  const [check, setCheck] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [providerList, setProviderList] = useState([]);
  const [showInput, setShowInput] = useState(false);
  const [showProvider, setShowProvider] = useState(false)
  const [seoUrl, setSeoUrl] = useState("");
  const [seoUrlValidationMessage, setSeoUrlValidationMessage] = useState("");
  const latestRequestId = useRef(0);
  const controllerRef = useRef(null);
  const [isSeoUrlValid, setIsSeoUrlValid] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isSeoUrlEditable, setIsSeoUrlEditable] = useState(false);
  const [showMultipleImageBtn, setShowMultipleImageBtn] = useState(false);
  const [seoerorrshow, setSeoErrorShow] = useState(false);


  const createSeoUrl = (value) => {
    return `course-details/${providerName.length > 0 ? providerName.trim()
      .replace(/[^\w\s-]/g, "-")
      .replace(/\s+/g, "-")
      .replace(/-{2,}/g, "-")
      .replace(/(^-|-$)/g, "")
      .toLowerCase() : ""}/${value
        ?.trim()
        .replace(/[^\w\s-]/g, "-")
        .replace(/\s+/g, "-")
        .replace(/-{2,}/g, "-")
        .replace(/(^-|-$)/g, "")
        .toLowerCase()}`
  };

  const validateSeoUrl = (value) => {
    if (!value) {
      setSeoUrlValidationMessage("SEO URL can't be empty.");
      setIsSeoUrlValid(false);
      return false;
    }
    const seoUrlRegex = /^[a-z0-9-]+$/;
    const noConsecutiveDashes = !value.includes("--");
    const noLeadingOrTrailingDash = !/^[-]|[-]$/.test(value);
    return seoUrlRegex.test(value) && noConsecutiveDashes && noLeadingOrTrailingDash;
  };

  const handleSeoUrlValidation = async (value) => {

    const requestId = ++latestRequestId.current;

    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();
    if (!value) {
      setIsSeoUrlValid(false);
      setSeoUrlValidationMessage("Seo Url cannot be empty");
      return
    }
    const formattedSeoUrl = value;
    const requestBody = { SeoURL: formattedSeoUrl };
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Token 77927b69bb144b065ca11bf2a9d452819cd852db",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
      signal: controllerRef.current.signal,
    };

    try {
      const response = await fetch(
        global.apiLink +"/adminapi/CheckSeoURLinCourses",
        requestOptions
      );
      const result = await response.json();

      if (requestId === latestRequestId.current) {
        if (result.status_code === 200 && result.status === "SUCCESS") {
          if (result.IsSeoURLExist) {
            setIsSeoUrlValid(false);
            setSeoUrlValidationMessage("SEO URL already exists.");
          } else {
            setIsSeoUrlValid(true);
            setSeoUrlValidationMessage("SEO URL is correct.");
          }
        } else if (result.message === "Invalid SeoURL Format") {
          setIsSeoUrlValid(false);
          setSeoUrlValidationMessage("Invalid SeoURL. Both provider name and course title shoulde be include. Use lowercase letters, numbers, starts with course-details/ and single dashes.");
        }
        else {
          setIsSeoUrlValid(false);
          setSeoUrlValidationMessage("Invalid SEO URL.");
        }
      }
    } catch (error) {
      if (error.name !== "AbortError" && requestId === latestRequestId.current) {
        setSeoUrlValidationMessage("Error validating SEO URL.");
      }
    }
  };

  const handleTitleChange = (value) => {
    setTitle(value);
    if (value) {
      const generatedSeoUrl = createSeoUrl(value);
      setSeoUrl(generatedSeoUrl);
      setIsSeoUrlEditable(true);
      handleSeoUrlValidation(generatedSeoUrl);
    } else {
      setSeoUrl("");
      setIsSeoUrlEditable(false);
      setSeoUrlValidationMessage("");
    }
  };

  const handleSeoUrlChange = (value) => {
    setSeoUrl(value);

    if (true) {
      setSeoUrlValidationMessage("");
      setIsSeoUrlValid(true);
      handleSeoUrlValidation(value);
    } else {
      setSeoUrlValidationMessage(
        "Invalid SeoURL. Use lowercase letters, numbers, starts with articles/ and single dashes."
      );
      setIsSeoUrlValid(false);
    }
  };
  useEffect(() => {
   
  }, [seoUrl]);

  useEffect(() => {
    if (providerName && title) {
      const generatedSeoUrl = createSeoUrl(title);
      setSeoUrl(generatedSeoUrl);
      handleSeoUrlValidation(generatedSeoUrl); // Validate the new SEO URL
    }
  }, [providerName, title])

  useEffect(() => {
    CategoryMapList();
  }, [])

  const CategoryMapList = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(global.apiLink + "/adminapi/GetCategoryProviderName", requestOptions)
      .then(response => response.json())
      .then(result => {

        setCategoryList(result.category)
        setProviderList(result.providerName)
      })
      .catch(error => console.log('error', error));
  }

  const coursetype = [

    {
      type: "New course"
    },
    {
      type: "Popular course"
    },
    {
      type: "Best selling course"
    },
    {
      type: "Popular with student course"
    },
    {
      type: "Best course"
    },
    {
      type: "Offline course"
    },
    {
      type: "Free Course"
    },
    {
      type: "Paid Course"
    }
  ]

  const coursegroup = [
    {
      type: "Free"
    },
    {
      type: "Paid"
    }
  ]

  const NewCourse = () => {
    setPassLoader(true)
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    if (courseImage)
      formdata.append("CourseImage", courseImage);
    if (courseProviderLogo)
      formdata.append("CourseProviderLogo", courseProviderLogo);
    // formdata.append("ProviderImage", ProviderImage);
    if (title)
      formdata.append("CourseTitle", title);
    if (source)
      formdata.append("CourseSource", source);
    if (description)
      formdata.append("CourseDesc", description);
    if (url)
      formdata.append("CourseUrl", url);
    if (type)
      formdata.append("CourseType ", type);
    if (category)
      formdata.append("CourseCategory", category);
    // if (courseRelevant)
    //   formdata.append("CourseRelevant", courseRelevant);
    // if (certification)
    //   formdata.append("Certification", certification);
    // if (industry)
    //   formdata.append("Industry", industry);
    if (courseProvider)
      formdata.append("CourseProvider", courseProvider);
    if (courseGroup)
      formdata.append("CourseGroup", courseGroup);
    if (duration)
      formdata.append("CourseDuration", duration);
    if (providerName)
      formdata.append("ProviderName", providerName);
    // if (providerWebsite)
    //   formdata.append("ProviderWebsite", providerWebsite);
    // if (courseGroup)
    //   formdata.append("course_group", courseGroup);
    if (startDate)
      formdata.append("StartDate ", startDate);
    if (endDate)
      formdata.append("EndDate ", endDate);
    formdata.append("Status", check);
    formdata.append("SeoURL", seoUrl)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(global.apiLink +"/adminapi/Create-Newcourses", requestOptions)
      .then(response => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200) {
          // setModalShow(true);
          alert("Course Created succesfully!")
          history.push("/BiDashboard/courses");
        }
        else if (result.status_code === 300 && result.status === "fail") {
          alert("Course already inserted")
        }
        else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        }

        else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setPassLoader(false);
      });
  };

  const onBodyChangeHandler = (textEditorValue) => {
    setDescription(textEditorValue);
  };
  return (
    <div className={classes.top_div}>

      {modalShow && <Modal heading={"Data inserted successfully"} onClick={() => history.push("/BiDashboard/courses")} />}
      {erorrshow && <Modal heading={"Required Fields Missing"} onClick={() => setErrorShow(false)} />}
      {internalErrorShow && <Modal heading={"Internal server error"} onClick={() => setInternalErrorShow(false)} />}
      {seoerorrshow && (
        <ErrorModal
          heading={"Please provide a valid SEO URL"}
          onClick={() => setSeoErrorShow(false)}
        />
      )}
      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Add Course</div>
            <Link to="/BiDashboard/courses" className={classes.BackButton}>
              <BackButton />
            </Link>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Course Title"}
                placeholder={"Course Title"}
                onChange={(e) => handleTitleChange(e.target.value)}
                value={title}
                required
              />

              {/* <EventInputs
                input={"SingleLineInput"}
                label={"Course Provider"}
                placeholder={"Course Provider"}
                onChange={(e) => setCourseProvider(e.target.value)}
                value={courseProvider}
              /> */}
              <EventInputs
                input={"SingleLineInput"}
                label={"Course Duration"}
                placeholder={"Course Duration"}
                onChange={(e) => setDuration(e.target.value)}
                value={duration}
              />
              <EventInputs
                input={"smalldropdown"}
                label={"Course Type"}
                placeholder={"Course Type"}
                onChange={(e) => setType(e.target.value)}
                displayData={coursetype}
                value={type}
              />
              <EventInputs
                input={"smalldropdown"}
                label={"Course Group"}
                placeholder={"Course Group"}
                onChange={(e) => setCourseGroup(e.target.value)}
                displayData={coursegroup}
                value={courseGroup}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={" Course Url"}
                placeholder={"course Url"}
                onChange={(e) => setSource(e.target.value)}
                value={source}
              />
              <div style={{ position: "relative" }}>
                <EventInputs
                  input={"SingleLineInput"}
                  label={"SEO URL"}
                  placeholder={"/course-details/provider-name/course-name"}
                  onChange={(e) => handleSeoUrlChange(e.target.value)}
                  disabled={!isSeoUrlEditable}
                  required
                  id="seoUrl"
                  name="seoUrl"
                  value={seoUrl}
                />
                {seoUrlValidationMessage && (
                  <div
                    className={`${classes.ValidationTooltip} ${isSeoUrlValid ? classes.successMessage : classes.errorMessage
                      }`}
                  >
                    {seoUrlValidationMessage}
                  </div>
                )}
              </div>
              {/* <EventInputs
                input={"MultiLineInput"}
                label={"Description"}
                placeholder={"Description"}
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              /> */}
              <div className={classes.AddHrProfessional_Description}>Description</div>
              <div className={classes.AddHrProfessional_Desc}>
                {/* <SunEditor
                    onChange={(e) => {
                      setDescription(e);
                    }}
                    value={description}
                    setContents={description}
                  /> */}
                <div>
                  <TextEditor onChangeContent={setDescription} value={description} />
                </div>
              </div>
              {/* <div className={classes.AddHrProfessional_Description}>Description</div>
              <div className={classes.textEditorWrapper}>
                <TextEditor onChange={onBodyChangeHandler} value={description}/>
              </div> */}

            </div>
            <div className={classes.input}>




              <div className={classes.AddHrProfessional_Description}>
                <div>Category</div>
                {!showInput ?
                  <button onClick={() => setShowInput(true)} className={classes.ButtonCategory}>Add new category</button> :
                  <button onClick={() => setShowInput(false)} className={classes.ButtonCategory}>Back</button>}
              </div>
              {/* {showInput?<EventInputs
                input={"SingleLineInput"}
                label={"category"}
                placeholder={"category"}
                onChange={(e) => setCategory(e.target.value)}
                value={category} */}
              {showInput ?
                <input
                  type="text"
                  name="tags"
                  placeholder="Add new category"
                  value={category}
                  className={classes.CategoryInput}
                  onChange={(e) => setCategory(e.target.value)}
                />
                :
                <div className={classes.AddHrProfessional_Desc}>
                  <select onChange={(e) => setCategory(e.target.value)} className={classes.inputArea_dropdown} value={category} >
                    <><option value="" disabled>-- Select Category --</option>
                    </>
                    {
                      categoryList !== undefined &&

                      categoryList.map((item, id) => {
                        return (<>
                          <option key={item.id} value={item.course_category}>{item.course_category}</option></>)
                      })
                    }
                  </select>
                </div>}

              {/* <EventInputs
                input={"SingleLineInput"}
                label={"Provider Name"}
                placeholder={"Provider Name"}
                onChange={(e) => setProviderName(e.target.value)}
                value={providerName}
              /> */}
              <div className={classes.AddHrProfessional_Description}>
                <div>Provider Name <span className="required-lable">*</span></div>
                {!showProvider ?
                  <button onClick={() => setShowProvider(true)} className={classes.ButtonCategory}>Add Provider</button> :
                  <button onClick={() => setShowProvider(false)} className={classes.ButtonCategory}>Back</button>}
              </div>
              {showProvider ?
                <input
                  type="text"
                  name="tags"
                  placeholder="Add Provider Name"
                  value={providerName}
                  required
                  className={classes.CategoryInput}
                  onChange={(e) => setProviderName(e.target.value)}
                />
                :
                <div className={classes.AddHrProfessional_Desc}>
                  <select onChange={(e) => setProviderName(e.target.value)} className={classes.inputArea_dropdown} value={providerName} >
                    <><option value="" disabled>-- Select Provider Name --</option>
                    </>

                    {
                      providerList !== undefined &&
                      providerList.map((item, id) => {
                        return (<>
                          <option key={item.id} value={item.providerName}>{item.providerName}</option></>)
                      })
                    }
                  </select>
                </div>}
              <EventInputs
                input={"DateInput"}
                label={"Start Date"}
                placeholder={"Start Date"}
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
              />
              <EventInputs
                input={"DateInput"}
                label={"End Date"}
                placeholder={"End Date"}
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
              />
              {/* 
              <EventInputs
                input={"SingleLineInput"}
                label={"Industry"}
                placeholder={"Industry"}
                onChange={(e) => setIndustry(e.target.value)}
                value={industry}
              /> */}
              <EventInputs
                input={"ImageInput"}
                label={"Course Image"}
                placeholder={"Course Image"}
                onChange={(e) => setCourseImage(e.target.files[0])}
                type={"file"}
                accept=".png, .jpg, .jpeg, .webp"
                required
              />

              <EventInputs
                input={"ImageInput"}
                label={"Course Provider Logo"}
                placeholder={"Course Provider Logo"}
                onChange={(e) => setCourseProviderLogo(e.target.files[0])}
                type={"file"}
                accept=".png, .jpg, .jpeg, .webp"
              />

            </div>
            <div className={classes.checkbox}>
              <EventInputs
                input={"CheckBox"}
                onChange={() => setCheck(!check === true ? 1 : 0)}
                label={"Want to publish"}
                value={check}
              />
            </div>
          </div>
        </div>
        <button className={classes.Upload_button} onClick={() => {
          if (isSeoUrlValid) {
            NewCourse();
            setShowMultipleImageBtn(true);
          }
          else {
            // alert("Please provide a valid SEO URL");
            setSeoErrorShow(true)
          }
        }}>
          {passLoader ? (
            <Loader type="TailSpin" color="white" height={20} width={20} />
          ) : (
            <>
              submit
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default AddCourses;
