import React, { useState, useRef, useEffect } from "react";
import EventInputs from "./EventInputs";
import classes from "./AddEvents.module.css";
import { Link, useHistory } from "react-router-dom";
import BackButton from "../BackButton";
import Modal from '../Modal';
import TextEditor from "../TextEditor";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import ErrorModal from "../ErrorModal";

const AddEvents = () => {

  const history = useHistory()

  const [eventName, setEventName] = useState();
  const [location, setLocation] = useState();
  const [description, setDescription] = useState();
  const [type, setType] = useState();
  const [organizer, setOrganizer] = useState();
  const [speakerName, setSpeakerName] = useState();
  const [speakerDesignation, setSpeakerDesignation] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [eventImage, setEventImage] = useState();
  const [eventSpeakerImage, setEventSpeakerImage] = useState();
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [categories, setCategories] = useState();
  const [check, setCheck] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [url, setUrl] = useState();
  const [seoUrl, setSeoUrl] = useState("");
  const [seoUrlValidationMessage, setSeoUrlValidationMessage] = useState("");
  const latestRequestId = useRef(0);
  const controllerRef = useRef(null);
  const [isSeoUrlValid, setIsSeoUrlValid] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isSeoUrlEditable, setIsSeoUrlEditable] = useState(false);
  const [showMultipleImageBtn, setShowMultipleImageBtn] = useState(false);
  const [seoerorrshow, setSeoErrorShow] = useState(false);

  const createSeoUrl = (value) => {
    return `/event/${value
      ?.trim()
      .replace(/[^\w\s-]/g, "-")
      .replace(/\s+/g, "-")
      .replace(/-{2,}/g, "-")
      .replace(/(^-|-$)/g, "")
      .toLowerCase()}`
  };

  const validateSeoUrl = (value) => {
    if (!value) {
      setSeoUrlValidationMessage("SEO URL can't be empty.");
      setIsSeoUrlValid(false);
      return false;
    }
    const seoUrlRegex = /^[a-z0-9-]+$/;
    const noConsecutiveDashes = !value.includes("--");
    const noLeadingOrTrailingDash = !/^[-]|[-]$/.test(value);
    return seoUrlRegex.test(value) && noConsecutiveDashes && noLeadingOrTrailingDash;
  };

  const handleSeoUrlValidation = async (value) => {

    const requestId = ++latestRequestId.current;

    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();
    if (!value) {
      setIsSeoUrlValid(false);
      setSeoUrlValidationMessage("Seo Url cannot be empty");
      return
    }
    const formattedSeoUrl = value;
    const requestBody = { SeoURL: formattedSeoUrl };
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Token 77927b69bb144b065ca11bf2a9d452819cd852db",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
      signal: controllerRef.current.signal,
    };

    try {
      const response = await fetch(
        global.apiLink +"/adminapi/CheckSeoURLinEvents",
        requestOptions
      );
      const result = await response.json();

      if (requestId === latestRequestId.current) {
        if (result.status_code === 200 && result.status === "SUCCESS") {
          if (result.IsSeoURLExist) {
            setIsSeoUrlValid(false);
            setSeoUrlValidationMessage("SEO URL already exists.");
          } else {
            setIsSeoUrlValid(true);
            setSeoUrlValidationMessage("SEO URL is correct.");
          }
        } else if (result.message === "Invalid SeoURL Format") {
          setIsSeoUrlValid(false);
          setSeoUrlValidationMessage("Invalid SeoURL. Use lowercase letters, numbers, starts with /event/ and single dashes.");
        }
        else {
          setIsSeoUrlValid(false);
          setSeoUrlValidationMessage("Invalid SEO URL.");
        }
      }
    } catch (error) {
      if (error.name !== "AbortError" && requestId === latestRequestId.current) {
        setSeoUrlValidationMessage("Error validating SEO URL.");
      }
    }
  };

  const handleTitleChange = (value) => {
    setEventName(value);
    if (value) {
      const generatedSeoUrl = createSeoUrl(value);
      setSeoUrl(generatedSeoUrl);
      setIsSeoUrlEditable(true);
      handleSeoUrlValidation(generatedSeoUrl);
    } else {
      setSeoUrl("");
      setIsSeoUrlEditable(false);
      setSeoUrlValidationMessage("");
    }
  };

  const handleSeoUrlChange = (value) => {
    setSeoUrl(value);

    if (true) {
      setSeoUrlValidationMessage("");
      setIsSeoUrlValid(true);
      handleSeoUrlValidation(value);
    } else {
      setSeoUrlValidationMessage(
        "Invalid SeoURL. Use lowercase letters, numbers, starts with articles/ and single dashes."
      );
      setIsSeoUrlValid(false);
    }
  };
  useEffect(() => {
  
  }, [seoUrl]);

  const NewEvent = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("eventName", (eventName ? eventName : ""));
    formdata.append("eventLocation", (location ? location : ""));
    formdata.append("eventDescription", (description ? description : ""));
    formdata.append("eventType", (type ? type : ""));
    formdata.append("event_time", (time ? time : ""));
    formdata.append("event_date", (date ? date : ""));
    formdata.append("event_organizer", (organizer ? organizer : ""));
    formdata.append("event_speaker_name ", (speakerName ? speakerName : ""));
    formdata.append("event_speaker_designation", (speakerDesignation ? speakerDesignation : ""));
    formdata.append("event_speaker_image", (eventSpeakerImage ? eventSpeakerImage : ""));
    formdata.append("event_image", (eventImage ? eventImage : ""));
    formdata.append("event_start_time", (startTime ? startTime : ""));
    formdata.append("event_end_time", (endTime ? endTime : ""));
    formdata.append("eventStartDate", (startDate ? startDate : ""));
    formdata.append("eventEndDate", (endDate ? endDate : ""));
    formdata.append("isPublished", check);
    formdata.append("eventCategories", (categories ? categories : ""));
    formdata.append("eventUrl", (url ? url : ""));
    formdata.append("SeoURL", (seoUrl ? seoUrl : ""));

    console.log(formdata);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(global.apiLink +"/adminapi/new-event", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200) {
          setModalShow(true);
        }
        else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        }
        else if (result.status_code === 300) {
          setErrorShow(true);
        }

      })
      .catch((error) => console.log("error", error));
  };

  const onBodyChangeHandler = (textEditorValue) => {
    setDescription(textEditorValue);
  };

  return (
    <div className={classes.top_div}>
      {seoerorrshow && (
        <ErrorModal
          heading={"Please provide a valid SEO URL"}
          onClick={() => setSeoErrorShow(false)}
        />
      )}
      {modalShow && <Modal heading={"Data inserted successfully"} onClick={() => history.push("/BiDashboard/events")} />}
      {erorrshow && <Modal heading={"Required Fields Missing"} onClick={() => setErrorShow(false)} />}
      {internalErrorShow && <Modal heading={"Internal server error"} onClick={() => setInternalErrorShow(false)} />}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Add Event</div>
            <Link to="/BiDashboard/events" className={classes.BackButton}>
              <BackButton />
            </Link>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Event name"}
                placeholder={"Event Name"}
                required
                onChange={(e) => handleTitleChange(e.target.value)}
                value={eventName}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Location"}
                placeholder={"Location"}
                onChange={(e) => setLocation(e.target.value)}
                value={location}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Event Organizer"}
                placeholder={"Event Organizer"}
                onChange={(e) => setOrganizer(e.target.value)}
                value={organizer}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Event Speaker Name"}
                placeholder={"Event Speaker Name"}
                onChange={(e) => setSpeakerName(e.target.value)}
                value={speakerName}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Type"}
                placeholder={"Type"}
                onChange={(e) => setType(e.target.value)}
                value={type}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={" Speaker Designation"}
                placeholder={"Speaker Designation"}
                onChange={(e) => setSpeakerDesignation(e.target.value)}
                value={speakerDesignation}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"categories"}
                placeholder={"categories"}
                onChange={(e) => setCategories(e.target.value)}
                value={categories}
              />
              <div style={{ position: "relative" }}>
                <EventInputs
                  input={"SingleLineInput"}
                  label={"SEO URL"}
                  placeholder={"event/event-name"}
                  onChange={(e) => handleSeoUrlChange(e.target.value)}
                  disabled={!isSeoUrlEditable}
                  required
                  id="seoUrl"
                  name="seoUrl"
                  value={seoUrl}
                />
                {seoUrlValidationMessage && (
                  <div
                    className={`${classes.ValidationTooltip} ${isSeoUrlValid ? classes.successMessage : classes.errorMessage
                      }`}
                  >
                    {seoUrlValidationMessage}
                  </div>
                )}
              </div>
              {/* <EventInputs
                input={"MultiLineInput"}
                label={"Description"}
                placeholder={"Description"}
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              /> */}
              <div className={classes.AddHrProfessional_Description}>Description</div>
              <div className={classes.AddHrProfessional_Desc}>
                {/* <SunEditor
                    onChange={(e) => {
                      setDescription(e);
                    }}
                    value={description}
                    setContents={description}
                  /> */}
                <div>
                  <TextEditor onChangeContent={setDescription} value={description} />
                </div>
              </div>
              {/* <div className={classes.AddHrProfessional_Description}>Description</div>
              <div className={classes.textEditorWrapper}>
                <TextEditor onChange={onBodyChangeHandler} value={description}/>
              </div> */}
            </div>
            <div className={classes.input}>
              <EventInputs
                input={"TimeInput"}
                label={"Event Start Time"}
                placeholder={"Event Start Time"}
                onChange={(e) => setStartTime(e.target.value)}
                value={startTime}
              />
              <EventInputs
                input={"TimeInput"}
                label={"Event End Time"}
                placeholder={"Event End Time"}
                onChange={(e) => setEndTime(e.target.value)}
                value={endTime}
              />
              <EventInputs
                input={"DateInput"}
                label={"Event Start Date"}
                placeholder={"Event Start Date"}
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
              />
              <EventInputs
                input={"DateInput"}
                label={"Event End Date"}
                placeholder={"Event End Date"}
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
              />
              <EventInputs
                input={"TimeInput"}
                label={"Event Time"}
                placeholder={"Event Time"}
                onChange={(e) => setTime(e.target.value)}
                value={time}
              />
              <EventInputs
                input={"DateInput"}
                label={"Event Date"}
                placeholder={"Event Date"}
                onChange={(e) => setDate(e.target.value)}
                value={date}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Redirection Url"}
                placeholder={"Redirection Url"}
                onChange={(e) => setUrl(e.target.value)}
                value={url}
              />
              <EventInputs
                input={"ImageInput"}
                label={"Event Card Image "}
                placeholder={"Event Speaker Image "}
                onChange={(e) => setEventSpeakerImage(e.target.files[0])}
                type={"file"}
                accept=".png, .jpg, .jpeg, .webp"
              />
              <EventInputs
                input={"ImageInput"}
                label={"Event Image"}
                placeholder={"Event Image"}
                required
                onChange={(e) => setEventImage(e.target.files[0])}
                type={"file"}
                accept=".png, .jpg, .jpeg, .webp"
              />
            </div>
            <div className={classes.checkbox}>
              <EventInputs
                input={"CheckBox"}
                onChange={() => setCheck(!check === true ? 1 : 0)}
                label={"Want to publish this event"}
                value={check}
              />
            </div>
          </div>
        </div>
        <button className={classes.Upload_button} onClick={() => {
          if (isSeoUrlValid) {
            NewEvent();
            setShowMultipleImageBtn(true);
          }
          else {
            // alert("Please provide a valid SEO URL");
            setSeoErrorShow(true)
          }
        }}>
          submit
        </button>
      </div>
    </div>
  );
};

export default AddEvents;
