import React, { useState, useEffect, useRef } from "react";
import classes from "./CompanyList.module.css";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { useHistory, Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
//import LoadingSpinner from "../../Components/LoadingSpinner";
//import ExcelDownload from "../../Components/Articles/ExcelDownload";
//import Paginate from "../../Components/Pagination";
import ConfirmModal from "../Components/ConfirmModal";
import RemarkModal from "../Components/RemarkModal";
import Modal from "../Components/Modal";
import { BsEye } from "react-icons/bs";
//import QuestionModal from "./QuestionModal";
import LoadingSpinner from "../Components/LoadingSpinner";
import ExcelDownload from "../Components/Articles/ExcelDownload";
import Paginate from "../Components/Pagination";
import Loader from "react-loader-spinner";
import CompanyModal from "../Components/CompanyModal/CompanyModal";

const CompanyList = (props) => {
  const [data, setData] = useState([]);
  const [orderApproveLoading, setOrderApproveLoading] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [errors, setErrors] = useState(false);
  const doc = new jsPDF();
  const [currentPage, setCurrentPage] = useState(1);
  const [modalShow, setModalShow] = useState(false);

  const [count, setCount] = useState(0);
  const [QAUUID, setQAUUID] = useState("");
  const [errorModal, setErrorModal] = useState("");
  const [remark, setRemark] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [remarkModal, setremarkModal] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedRecruiterUUID, setSelectedRecruiterUUID] = useState(null);
  const [modelData, setModelData] = useState("");

  const perPage = 10;

  const tableRef = useRef();
  const formValidation = () => {
    let isValid = true;
    let error = {};

    if (!startDate || !endDate) {
      isValid = false;
      error.startDate = "Please Select Both Dates";
    }

    setErrors(error);
    return isValid;
  };
  let today = new Date();
  today.setDate(today.getDate() - 60);
  let date = new Date(today)
    .toLocaleDateString("in", {
      day: "2-digit",
      year: "numeric",
      month: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  useEffect(() => {
    fetchQuestionList();
  }, [currentPage]);

  const history = useHistory();

  const fetchQuestionList = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      page: currentPage,
      content: perPage,
      startdate: startDate,
      enddate: endDate,
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_API_URL + "/adminapi/RecruiterList", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
        setCount(result.count);
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  const getDetailApi = (recruiterUUID) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      recruiterUUID: recruiterUUID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/RecruiterDetailbyId",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        const data = result.data;
      })
      .catch((error) => console.log("Error:", error));
  };

  const AprooveApiNew = (recruiterUUID, status, companyStatus, remark) => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      recruiterUUID: recruiterUUID,
      isAdminApproved: status === 1 ? "1" : "0",
      isApproved: companyStatus,
      remark: remark,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/RecruiterApproval",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          fetchQuestionList(); // Refresh data on success
        }
      })
      .catch((error) => console.log("Error:", error))
      .finally(() => {
        // Remove recruiterUUID from loading state if applicable
        console.log("Action completed");
      });
  };

  const onModalCloseHandler = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={classes.dashboard_container}>
      <CompanyModal
        isModalOpen={isModalOpen}
        iddata={modelData}
        setIsModalOpen={setIsModalOpen}
        onClose={onModalCloseHandler}
      />{" "}
      <div className={classes.container}>
        {remarkModal && (
          <RemarkModal
            heading={`Are you sure you want to ${selectedAction.toLowerCase()} this data?`}
            onRemarkSubmit={(remark) => {
              // Call the AprooveApiNew function with selected action, recruiterUUID, and remark
              if (selectedAction === "Approve") {
                AprooveApiNew(selectedRecruiterUUID, 1, "Approved", remark);
              } else if (selectedAction === "Reject") {
                AprooveApiNew(selectedRecruiterUUID, 0, "Rejected", remark);
              }
              setremarkModal(false); // Close the modal after confirming
            }}
            onClose={() => setremarkModal(false)} // Close the modal if canceled
          />
        )}

        {confirmModalShow && (
          <ConfirmModal
            heading={`Are you sure you want to ${selectedAction.toLowerCase()} this data?`}
            onClick={() => {
              // Call the AprooveApiNew function with selected action and recruiterUUID
              if (selectedAction === "Approve") {
                AprooveApiNew(selectedRecruiterUUID, 1, "Approved");
              } else if (selectedAction === "Reject") {
                AprooveApiNew(selectedRecruiterUUID, 0, "Rejected");
              }
              setConfirmModalShow(false); // Close the modal after confirming
            }}
            onClose={() => setConfirmModalShow(false)} // Close the modal if canceled
          />
        )}
        {errorModal && (
          <Modal
            heading={"Unable to delete the data."}
            onClick={() => {
              setErrorModal(false);
              setConfirmModalShow(false);
            }}
          />
        )}
        <div className={classes.header}>Company List :</div>
        <div className={classes.DateFilter_container}>
          <div className={classes.button_container}>
            <CSVLink
              data={data ? data : ""}
              filename={"/QuizQuestionList.csv"}
              target="_blank"
            >
              <button className={classes.button_articleList}>CSV</button>
            </CSVLink>
            <ExcelDownload csvData={data} fileName="/QuizQuestionList" />
          </div>
          <div className={classes.dateDiv}>
            <div className={classes.filter}>
              <label className={classes.dateLable}>Start Date</label>
              <input
                type="date"
                value={startDate || date}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <label className={classes.dateLable}>End Date</label>
              <input
                type="date"
                value={
                  endDate ||
                  new Date()
                    .toLocaleDateString("in", {
                      day: "2-digit",
                      year: "numeric",
                      month: "2-digit",
                    })
                    .split("/")
                    .reverse()
                    .join("-")
                }
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <div className={classes.form_error}>{errors.startDate}</div>
            <div className={classes.buttons}>
              <button
                type="button"
                className="button"
                onClick={() =>
                  formValidation()
                    ? fetchQuestionList()
                    : console.log("Something Wrong")
                }
              >
                {" "}
                Search
              </button>
            </div>
          </div>
        </div>


        {isLoading ? (
          <div className={classes.spinner}>
            <LoadingSpinner />{" "}
          </div>
        ) : data.length ? (
          <>
            <table className={classes.orders_table}>
              <thead>
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>S.No.</th>
                  <th className={classes.table_heading}>Company Name</th>
                  <th className={classes.table_heading}>Email</th>
                  <th className={classes.table_heading}>Remark</th>
                  <th className={classes.table_heading}>Phone No.</th>
                  <th className={classes.table_heading}>Status</th>
                  <th className={classes.table_heading}>View</th>
                  <th className={classes.table_heading}>Approve</th>
                  <th className={classes.table_heading}>Reject/Cancel</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(data) && data.length > 0 ? (
                  data.map((item, index) => (
                    <tr
                      className={classes.table_row}
                      key={item.recruiterUUID}
                    >
                      <td className={classes.table_td}>{(currentPage - 1) * perPage + index + 1}</td>
                      <td className={classes.table_td}>{item.companyName}</td>
                      <td className={classes.table_td}>{item.email}</td>
                      <td className={classes.table_tdremark}>
                        {item.remark}
                      </td>
                      <td className={classes.table_td}>{item.mobile}</td>
                      <td className={classes.table_td}>{item.isApproved}</td>
                      <td
                        className={classes.table_td}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setModelData(item);
                          setIsModalOpen(true);
                          getDetailApi(item.recruiterUUID); // Pass candidateUUID dynamically
                        }}
                      >
                        <span className={classes.table_td_Link}>
                          <BsEye />
                        </span>
                      </td>
                      <td className={classes.table_td}>
                        <button
                          onClick={() => {
                            setSelectedAction("Approve");
                            setSelectedRecruiterUUID(item.recruiterUUID);
                            setConfirmModalShow(true);
                          }}
                          className={`${classes.approve_button} ${item.isApproved !== "Pending" ||
                            orderApproveLoading.some(
                              (loader) =>
                                loader.recruiterUUID === item.recruiterUUID &&
                                loader.action === "Approve"
                            )
                            ? classes.disabled_button
                            : ""
                            }`}
                          disabled={
                            item.isApproved !== "Pending" ||
                            orderApproveLoading.some(
                              (loader) =>
                                loader.recruiterUUID === item.recruiterUUID &&
                                loader.action === "Approve"
                            )
                          }
                        >
                          {orderApproveLoading.some(
                            (loader) =>
                              loader.recruiterUUID === item.recruiterUUID &&
                              loader.action === "Approve"
                          ) && item.isApproved === "Pending" ? (
                            <Loader
                              type="TailSpin"
                              color="white"
                              height={15}
                              width={50}
                            />
                          ) : (
                            <>Approve</>
                          )}
                        </button>
                      </td>
                      <td className={classes.table_td}>
                        <button
                          onClick={() => {
                            setSelectedAction("Reject");
                            setSelectedRecruiterUUID(item.recruiterUUID);
                            setremarkModal(true); // Open the remark modal instead of the confirm modal
                          }}
                          className={`${classes.reject_button} ${item.isApproved !== "Pending" ||
                            orderApproveLoading.some(
                              (loader) =>
                                loader.recruiterUUID === item.recruiterUUID &&
                                loader.action === "Reject"
                            )
                            ? classes.disabled_button
                            : ""
                            }`}
                          disabled={
                            item.isApproved !== "Pending" ||
                            orderApproveLoading.some(
                              (loader) =>
                                loader.recruiterUUID === item.recruiterUUID &&
                                loader.action === "Reject"
                            )
                          }
                        >
                          {orderApproveLoading.some(
                            (loader) =>
                              loader.recruiterUUID === item.recruiterUUID &&
                              loader.action === "Reject"
                          ) && item.isApproved === "Pending" ? (
                            <Loader
                              type="TailSpin"
                              color="white"
                              height={15}
                              width={50}
                            />
                          ) : (
                            <>Reject</>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className={classes.noDataFound}>
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <div>
              {count > 10 && (
                <Paginate
                  maxPage={count}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  perPage={perPage}
                />
              )}
            </div>
          </>
        ) : (
          <h2 className={classes.serachErrorText}>
            "No Data Found !"
          </h2>
        )}

      </div>
    </div>
  );
};

export default CompanyList;
